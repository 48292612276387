
import './clientRapportSingle.scss'
import { Form, Container, InputGroup, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import RapportsPassagesDataService from '../../services/rapportsPassagesServices'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import TextareaAutosize from '@mui/base/TextareaAutosize';





function ClientRapportPassageSingle({ id,close }) {

    //states
    const [date, setDate] = useState("")
    const [client, setClient] = useState("")
    const [present, setPresent] = useState("")
    const [clothes, setClothes] = useState("")
    const [presentation, setPresentation] = useState("")
    const [cart, setCart] = useState("")
    const [products, setProducts] = useState("")
    const [colors, setColors] = useState("")
    const [remarks, setRemarks] = useState("")
    const [actions, setActions] = useState("")
    const [clientName, setClientName] = useState("")
    const [images, setImages] = useState([])
    const [signature, setSignature] = useState('')
    

    const [message, setMessage] = useState({ error: false, msg: "" })


    

    //voir les données
    const handleEdit = async () => {
        setMessage("")

        try {
            const docSnap = await RapportsPassagesDataService.getRapportPassage(id)
            setDate(docSnap.data().date)
            setClient(docSnap.data().client)
            setPresent(docSnap.data().present)
            setClothes(docSnap.data().clothes)
            setPresentation(docSnap.data().presentation)
            setCart(docSnap.data().cart)
            setProducts(docSnap.data().products)
            setColors(docSnap.data().colors)
            setRemarks(docSnap.data().remarks)
            setActions(docSnap.data().actions)
            setClientName(docSnap.data().clientName)
            setImages(docSnap.data().image)
            setSignature(docSnap.data().signature)
        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }
    }

    useEffect(() => {
        if (id !== undefined && id !== "") {
            handleEdit()
        }
    }, [id])

    //imprimer le rapport, créer PDF et envoyer par mail
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `rapport de Passage ${date}${client} `,
        
    })

        return (
            <>
                <Button className='m-5 mb-3 bg-secondary' onClick={handlePrint} style={{ cursor: "pointer" }}>Print / PDF </Button>
                
                <div ref={componentRef} className='text-center'>
                    <h4 className='mb-3 '>Rapport de Passage du {date}</h4>
                    <Container className='w-50 mb-5'>

                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Client</InputGroup.Text>
                            <Form.Control type="Texte" value={client} readOnly={true} />
                        </InputGroup>
                        <InputGroup className="p-3" >
                            <InputGroup.Text>Présents</InputGroup.Text>
                            <Form.Control type="Texte" value={present} readOnly={true} />
                        </InputGroup>
                    </Container>
                    <div className='text-center mt-2'>
                        <h4>Rencontre du personnel sur place</h4>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>évaluation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Vêtements de travail</td>
                                    <td>{clothes}</td>
                                </tr>
                                <tr>
                                    <td>Présentation du personnel exécutant</td>
                                    <td>{presentation}</td>
                                </tr>
                                <tr>
                                    <td>Présentation chariot</td>
                                    <td>{cart}</td>
                                </tr>
                                <tr>
                                    <td>Utilisation respective des produits et dosages</td>
                                    <td>{products}</td>
                                </tr>
                                <tr>
                                    <td>Respect des codes couleurs</td>
                                    <td>{colors}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <Container className='w-50 mt-5'>
                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Constatations sur le nettoyage</InputGroup.Text>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="pas de constatation particulière"
                                style={{ width: '100%' }}
                                className='mb-5 p-3'
                                value={remarks}
                                readOnly={true}
                            />
                        </InputGroup>

                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Actions à prendre</InputGroup.Text>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="pas d'action requise"
                                style={{ width: '100%' }}
                                className='mb-5 p-3'
                                value={actions}
                                readOnly={true}
                            />
                        </InputGroup>



                        <InputGroup className="mb-1 p-3">
                            <InputGroup.Text>Nom du Client</InputGroup.Text>
                            <Form.Control type="Texte" value={clientName} readOnly={true} />
                        </InputGroup>



                        <div className="mb-1 p-3 signature">
                            <p><img src={signature} alt="" />Signature client</p>
                        </div>
                        {images.map((image) => {

                            return (

                                <div key={image} className='mt-5'>
                                    <img src={image} alt="" style={{ width: '500px', margin: '10px' }} />
                                </div>
                            )


                        })}

                    </Container>
                    <Button className='retour m-3 p-3' onClick={close}>Retour à la liste</Button>
                </div>
            </>
        )
    }

  
export default ClientRapportPassageSingle