import React from 'react';
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarChart = ({ data, selectedCategory }) => {

    // Préparer les données pour recharts
    const processedData = data.flatMap((item) => {
        // Combine les produits, matériel, sacs, et papier dans un tableau unique avec leurs quantités
        const produitsData = item.produits.map((productData) => ({
            client: item.client,
            product: productData.product,
            category: "produits",
            qty: productData.qty,
        }));

        const materielData = item.materiel.map((productData) => ({
            client: item.client,
            product: productData.product,
            category: "matériel",
            qty: productData.qty,
        }));

        const sacsData = item.sacs.map((productData) => ({
            client: item.client,
            product: productData.product,
            category: "sacs",
            qty: productData.qty,
        }));

        const papierData = item.papier.map((productData) => ({
            client: item.client,
            product: productData.product,
            category: "papier",
            qty: productData.qty,
        }));

        return [...produitsData, ...materielData, ...sacsData, ...papierData];
    });

    // Si une catégorie est sélectionnée, filtrer les données en conséquence
    const filteredData = selectedCategory
        ? processedData.filter(item => item.category === selectedCategory)
        : processedData;

    const combinedData = filteredData.map(item => ({
        ...item,
        xAxisLabel: `${item.client} - ${item.category} - ${item.product}`,
    }));
    
    return ( 
        <div className="chart-wrapper">
            <ResponsiveContainer width="100%" height={400}>
                <RechartsBarChart data={combinedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis  dataKey="xAxisLabel" hide/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar 
                        dataKey="qty" 
                        fill={"#8884d8"}
                        name="Quantité"
                    />
                </RechartsBarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChart;
