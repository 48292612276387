import { db } from '../firebase-config'
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where, getDoc, orderBy} from 'firebase/firestore'

const suiviQualitesCollectionRef = collection(db,'suiviQualites')

class SuiviQualiteDataService {

    addSuiviQualite = ( newSuiviQualite) => {
        return addDoc(suiviQualitesCollectionRef,newSuiviQualite)
    }

    updateSuiviQualite = (id,updatedSuiviQualite) => {
        const suiviQualiteDoc = doc(db,"suiviQualites",id)
        return updateDoc(suiviQualiteDoc,updatedSuiviQualite)
    }

    deleteSuiviQualite = (id) => {
        const suiviQualiteDoc = doc(db,"suiviQualites",id) 
        return deleteDoc(suiviQualiteDoc)
    }

    getSuivisQualites = () => {
        const rapportsByDate = query (suiviQualitesCollectionRef,orderBy("createdAt","desc"))
        return getDocs(rapportsByDate)
    }
    getSuivisQualitesByClient = (client) => {
        const suivisQualitesByClient = query (suiviQualitesCollectionRef,where("client","==",client),where("checked","==","Vérifié"))
        return getDocs(suivisQualitesByClient)
    }

    getSuiviQualite = (id) => {
        const suiviQualiteDoc = doc(db,"suiviQualites",id)
        return getDoc(suiviQualiteDoc) 
    }
    
}

export default new SuiviQualiteDataService()