import './listProducts.scss'
import HomeSidebar from '../../components/homeSidebar/HomeSidebar'
import HomeNavbar from '../../components/homeNavbar/HomeNavbar'
import { useState, useEffect} from 'react'
import ProductDataService from '../../services/productsServices'
import { Button } from 'react-bootstrap'
import DataTableProducts from '../../components/datatTableProducts/DataTableProducts'
import NewProduct from '../newProduct/NewProduct'

function ListProducts(){

    const [products, setProducts] = useState([])

    useEffect(() => {
        getProducts()
    },[])

    const getProducts = async () => {
        const data = await ProductDataService.getAllProducts()
        setProducts(data.docs.map((doc) => ({...doc.data(),id:doc.id})))
    }

    const [productId, setProductId] = useState("")

    const [open, setOpen] = useState(false)

    const handleGetProductId = (id) => {
        setProductId(id)
        setOpen(true)
    }

    const close = () => {
        setOpen(false)
    }

    const show = () => {
        setOpen(true)
    }

    return(
        <div className="listProducts">
            <HomeSidebar/>
            <div className="listProductsContainer">
                <HomeNavbar/>
                <h1>Produits et Matériel</h1>
                {(open === false ) ? <DataTableProducts getProductId={handleGetProductId} open={show}/> : <NewProduct id={productId} setProductId={setProductId} />}
                {(open === true) ? <div className='mb-2'>
                    <Button className='retour m-3 p-3' onClick={close}>Retour à la liste</Button></div> : ""}
            </div>
        </div>
    )
}

export default ListProducts