import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'date-fns/locale/fr';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

import HomeNavbar from '../../components/homeNavbar/HomeNavbar';
import HomeSidebar from '../../components/homeSidebar/HomeSidebar';
import Widget from '../../components/widget/Widget';
import BarChart from '../../components/charts/BarChart';

import ChecksMaterielDataService from '../../services/checksMaterielServices';
import ClientDataService from '../../services/clientsService';

import './home.scss';

registerLocale('fr', fr);

function Home() {
    // States
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [category, setCategory] = useState('');
    const [client, setClient] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [clients, setClients] = useState([]);
    const [message, setMessage] = useState('');

    // Fetch clients on component mount
    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const data = await ClientDataService.getActiveClients();
            const sortedClients = data.docs.map(doc => ({ ...doc.data(), id: doc.id })).sort((a, b) => a.client.localeCompare(b.client));
            setClients(sortedClients);
        } catch (error) {
            setMessage('Erreur lors de la récupération des clients.');
        }
    };

    const checkIfCategoryIsUsed = (item) => {
        switch (category) {
            case "matériel":
                return item.matQty.some(num => parseInt(num) > 0);       
            case "papier":
                return item.papierQty.some(num => parseInt(num) > 0);       
            case "produits":
                return item.prodQty.some(num => parseInt(num) > 0);       
            case "sacs":
                return item.sacQty.some(num => parseInt(num) > 0);       
            default:
                return null;
        }
    };

    function convertirEnDate(dateString) {
        const [jour, mois, annee] = dateString.split('/').map(Number); // Divise la chaîne et convertit en nombres
        return new Date(annee, mois - 1, jour); // Les mois sont indexés à partir de 0
    }

    const fetchRapports = async () => {
        setIsLoading(true);
        setMessage('');
        try {
            const results = await ChecksMaterielDataService.getChecksMateriel();
            const filteredData = results.docs.map(doc => doc.data()).filter(item => {
                const matchesDate = (!startDate || convertirEnDate(item.date) >= new Date(startDate)) && (!endDate || convertirEnDate(item.date) <= new Date(endDate));
                const matchesClient = !client || item.client === client;
                const matchesCategory = !category || checkIfCategoryIsUsed(item);
                return matchesDate && matchesClient && matchesCategory;
            });
    
            // Traiter les données pour chaque item et pour chaque produit spécifique
            const processedData = filteredData.map(item => {
                const produitsData = item.prodQty?.map((qty, index) => ({
                    product: item.produit[index]?.product,  // Nom du produit
                    qty: +qty || 0,  // Quantité du produit
                })) || [];
    
                const materielData = item.matQty?.map((qty, index) => ({
                    product: item.materiel[index]?.product,  // Nom du matériel
                    qty: +qty || 0,  // Quantité du matériel
                })) || [];
    
                const sacsData = item.sacQty?.map((qty, index) => ({
                    product: item.sac[index]?.product,  // Nom du sac
                    qty: +qty || 0,  // Quantité du sac
                })) || [];
    
                const papierData = item.papierQty?.map((qty, index) => ({
                    product: item.papier[index]?.product,  // Nom du papier
                    qty: +qty || 0,  // Quantité du papier
                })) || [];
    
                return {
                    client: item.client,
                    produits: produitsData,
                    materiel: materielData,
                    sacs: sacsData,
                    papier: papierData,
                };
            });
    
            setData(processedData);
    
            if (!processedData.length) {
                setMessage('Pas de données disponibles pour les critères sélectionnés.');
            }
        } catch (error) {
            setMessage('Erreur lors de la récupération des données.');
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchRapports();
    };

    const renderCharts = () => {
        if (isLoading) {
            return (
                <div className="text-center mt-4">
                    <Spinner animation="border" variant="primary" />
                </div>
            );
        }

        if (!data.length) {
            return null;
        }

        return (
            <div className="chartsContainer mt-4">
                <BarChart data={data}  selectedCategory={category}/>
            </div>
        );
    };

    return (
        <div className="home">
            <HomeSidebar />
            <div className="homeContainer">
                <HomeNavbar />

                <div className="widgets">
                    <Widget type="rapportsPassage" />
                    <Widget type="rapportsMateriel" />
                    <Widget type="rapportsQualite" />
                </div>

                <div className="listContainer">
                    <h3 className="text-center mt-5 mb-4">Consommation Produits / Matériel</h3>

                    <Form className="text-center mb-4" onSubmit={handleSubmit}>
                        <Row className="justify-content-center">
                            <Col md={4} className="mb-3">
                                <Form.Label>Période - Début</Form.Label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className="form-control border-primary"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />                            </Col>
                            <Col md={4} className="mb-3">
                                <Form.Label>Période - Fin</Form.Label>
                                <input
                                    type="date"
                                    id="endDate"
                                    className="form-control border-primary"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />      
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={4} className="mb-3">
                                <Form.Label>Client</Form.Label>
                                <Form.Select value={client} onChange={(e) => setClient(e.target.value)}>
                                    <option value="">Choisir un client</option>
                                    {clients.map(client => (
                                        <option key={client.id} value={client.client}>{client.client}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={4} className="mb-3">
                                <Form.Label>Catégorie</Form.Label>
                                <Form.Select value={category} onChange={(e) => setCategory(e.target.value)}>
                                    <option value="">Choisir une catégorie</option>
                                    <option value="produits">Produits</option>
                                    <option value="matériel">Petit Matériel</option>
                                    <option value="sacs">Sacs Aspirateur</option>
                                    <option value="papier">Papier</option>
                                </Form.Select>
                            </Col>
                        </Row>

                        <Button className="mt-3" type="submit" variant="primary">
                            Voir Graphique
                        </Button>
                    </Form>

                    {message && (
                        <Alert variant='danger' dismissible onClose={() => setMessage('')}>
                            {message}
                        </Alert>
                    )}

                    <hr className='mb-5' />
                    {renderCharts()}
                </div>
            </div>
        </div>
    );
}

export default Home;
