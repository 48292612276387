import './dataTablePrivateUsers.scss'
import { useState, useEffect } from 'react'
import PrivateUserDataService from '../../services/privateUsersService'
import { DataGrid } from '@mui/x-data-grid'
import { privateUsersColumns } from '../../dataTablePrivateUsers'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'


const DataTablePrivateUsers = ({getPrivateUserId}) => {

    //Voir les utilisateurs
    const [privateUsers,setPrivateUsers] = useState([])

    useEffect(() => {
        getPrivateUsers()
    }, [])

    const getPrivateUsers = async () => {
        const data = await PrivateUserDataService.getPrivateUsers()
        setPrivateUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    //Supprimer un utilisateur
    const handleDelete =  (id) => {
        confirmAlert({
            title:'Confirmez',
            message: 'Etes-vous sûr de vouloir supprimer cet utilisateur ?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: async () =>  {
                        await PrivateUserDataService.deletePrivateUser(id)
                        getPrivateUsers()
                    }
                },
                {
                    label: 'Non',
                    
                }
            ]
        })
       
    }

    const actionColumn = [{
        field: "action",
        headerName: "",
        width: 150,
        renderCell: (params) => {
            return (
                <div className='cellAction'>


                    <div className="deleteButton" onClick={() => handleDelete(params.row.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="SUPPRIMER"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ff0000" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                    </svg></div>
                </div>
            )
        }
    }]


    return(
        <>
            <div className='datatable'>
                <div className="datatableTitle"></div>
                <DataGrid
                    className='datagrid'
                    rows={privateUsers}
                    columns={privateUsersColumns.concat(actionColumn)}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    //checkboxSelection
                />
            </div>

        </>
    )
}

export default DataTablePrivateUsers
