import "./login.scss"
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'
import PrivateUserDataService from '../../services/privateUsersService'
import { Link } from "react-router-dom"


const Login = () => {

    //login
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    const navigate = useNavigate()


    const { logIn } = useUserAuth()

    const [adminUsers, setAdminUsers] = useState([])
    const [workerUsers, setWorkerUsers] = useState([])
    const [clientUsers, setClientUsers] = useState([])

    const adminsArray = []
    const workersArray = []
    const clientsArray = []

    //get admins

    useEffect(() => {
        getAdminUsers()
    }, [])

    const getAdminUsers = async () => {
        const data = await PrivateUserDataService.getAdminUsers()
        setAdminUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    //get workers
    useEffect(() => {
        getWorkers()
    })

    const getWorkers = async () => {
        const data = await PrivateUserDataService.getWorkerUsers()
        setWorkerUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    //get clients
    useEffect(() => {
        getClients()
    })

    const getClients = async () => {
        const data = await PrivateUserDataService.getClientUsers()
        setClientUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        setError(false)
        adminUsers.map((adminUser) => {
            adminsArray.push(adminUser.password)

        })

        const adminPwd = adminsArray.find(v => v.includes(password))

        workerUsers.map((workerUser) => {
            workersArray.push(workerUser.password)

        })

        const workerPwd = workersArray.find(v => v.includes(password))


        clientUsers.map((clientUser) => {
            clientsArray.push(clientUser.password)

        })

        const clientPwd = clientsArray.find(v => v.includes(password))

        try {
            await logIn(email, password)
            if (password === adminPwd) {
                localStorage.setItem('isAdmin', 'true')
                navigate('/home')
            }
            else if (password === workerPwd) {
                localStorage.setItem('isWorker', 'true')
                navigate('/rapport_passage')
            }
            else if (password === clientPwd) {
                localStorage.setItem('isClient', 'true')
                navigate('/acces_clients')
            }

        }
        catch (err) {
            console.log(err);
            setError(true)
        }

    }




    return (
        <div className="loginContainer">
            <div className="isos">
                <div className="iso3">
                    <img src="/img/vca.svg" alt="certificat vca" className="isoImg3" />
                    <img src="/img/ugbn.png" alt="certificat ugbn" className="isoImg3" />
                </div>
                <div className="iso4">
                    <img src="/img/iso.png" alt="certificats iso" className="isoImg4" />
                </div>
            </div>
            <div className="img">
                <img src="/img/logo.svg" alt="logo netclean" className="logo" />
                
            </div>
            <div className="smallImg">
                <img src="/img/miniLogo.png" alt="logo netclean" className="miniLogo"/>
            </div>
            <div className="login">

                <form onSubmit={handleLogin}>
                    <input type="email" placeholder="email" onChange={e => setEmail(e.target.value)} />
                    <input type="password" placeholder="mot de passe" onChange={e => setPassword(e.target.value)} />
                    <button type="submit">Se connecter</button>
                    {error && <span>email ou mot de passe incorrect</span>}

                </form>
                <div className="cmleonResp">
                    <div className="rightsResp">
                        <p>Designed by CMLeon - ALL RIGHTS RESERVED © 2023 </p>
                        
                    </div>
                    <a href='https://cmleon.be/' target="_blank" rel="noreferrer"><img src="/img/logo-2.png" alt="logo cmleon" className="logoCmleonResp" /></a>

                </div>

            </div>
            <div className="cmleon">
                <div className="rights">
                    <p>Designed & coded by CMLeon </p>
                    <p>ALL RIGHTS RESERVED © 2023</p>
                </div>
                <a href='https://cmleon.be/' target="_blank" rel="noreferrer"><img src="/img/logo-2.png" alt="logo cmleon" className="logoCmleon" /></a>

            </div>

        </div>
    )

}

export default Login