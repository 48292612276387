import './dataTableRapportsPassages.scss'
import { useState, useEffect } from 'react'
import RapportsPassagesDataService from '../../services/rapportsPassagesServices'
import clientsService from '../../services/clientsService'
import { DataGrid } from '@mui/x-data-grid'
import { passageColumns } from '../../dataTableRapports'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { storage } from '../../firebase-config'
import { deleteObject, ref } from 'firebase/storage'
import ConfirmDelete from '../ConfirmDelete'




const DataTableRapportsPassages = ({ getRapportId }) => {


    //Voir les rapports
    const [rapports, setRapports] = useState([]);
    const [clients, setClients] = useState([]);
    const [filteredRapports, setFilteredRapports] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [clientFilter, setClientFilter] = useState('');


    useEffect(() => {
        getRapports();
        getClients();
    }, [])

    const getRapports = async () => {
        const data = await RapportsPassagesDataService.getRapportsPassages();
        let mappedData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setRapports(mappedData);
        setFilteredRapports(mappedData);
    }

    const getClients = async () => {
        const data = await clientsService.getActiveClients();
        let mappedData = data.docs.map((client) =>  client.data().client ).sort((a, b) => a.localeCompare(b));
        setClients(mappedData);
    }

    //Supprimer les rapports dans firestore et les images dans le storage
    const [images, setImages] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [id, setId] = useState('');
    const [message, setMessage ] = useState({error: false, msg:""});

    const findImages = async (id) => {

        if (id !== undefined && id !== "") {
            try {
                const docSnap = await RapportsPassagesDataService.getRapportPassage(id);
                setImages(docSnap.data().image);
            } catch (err) {
                console.log('error');
            }

        }

    }
    

    const deleteImages = () => {
        //TODO: condition inutile... 
        if (images !== []) {
            images.map((image) => {
                const storageRef = ref(storage, image);
                deleteObject(storageRef).then(() => {
                    console.log('success');
                }).catch((error) => {
                    console.log(error);
                })
                
            })
        }
            deleteRapport(id);
            getRapports();
    }


    const deleteRapport = async (id) => {
        try {
           
            setMessage('');
            await RapportsPassagesDataService.deleteRapportPassage(id);
            setMessage({error: false, msg: "Le rapport a été supprimé"});
        } catch (err) {
            setMessage(err);
        }


    }


    const handleDelete = async (id) => {
        setId(id)
        //TODO: condition inutile... 
        if(images !==[]){
            await findImages(id);
            setConfirm(true);
        }
       
        setConfirm(true);
    }


    const close = () => {
        setConfirm(false);
    }


    const actionColumn = [{
        field: "action",
        headerName: "",
        width: 150,
        renderCell: (params) => {
            return (
                <div className='cellAction'>

                    <div className="editButton" onClick={(e) => getRapportId(params.row.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="VOIR DETAIL OU MODIFIER"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffa500" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg></div>

                    <div className="deleteButton" onClick={() => handleDelete(params.row.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="SUPPRIMER"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ff0000" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                    </svg></div>
     
                </div>
            )
        }
    }]

    function convertDateToISOFormat(date) {
        // Découper la chaîne par le séparateur "/"
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}`;
    }

    const handleFilter = () => {
        // Filtrage par date
        const filtered = rapports.filter(rapport => {
            const date = new Date(convertDateToISOFormat(rapport.date))
            const matchesDate = (!startDate || date >= new Date(startDate)) && (!endDate || date <= new Date(endDate));
            const matchesClient = !clientFilter || rapport.client?.toLowerCase().includes(clientFilter.toLowerCase());
            return matchesDate && matchesClient;
        })
        setFilteredRapports(filtered);
    }

    return (
        <>
            {confirm === true ? (
                <ConfirmDelete deleteImages={deleteImages} close={close} message={message} setMessage={setMessage} />
            ) : (
                <div className="datatableRapports">
                    <div id="filters" className="filter-container mb-4 p-4 bg-white rounded shadow">
                        <h5 className="mb-3 text-primary">Filtres de recherche</h5>
                        <div className="row gy-3 align-items-end">
                            <div className="col-md-4">
                                <label htmlFor="startDate" className="form-label">
                                    <i className="bi bi-calendar-event me-2"></i>Date de début
                                </label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className="form-control border-primary"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="endDate" className="form-label">
                                    <i className="bi bi-calendar-check me-2"></i>Date de fin
                                </label>
                                <input
                                    type="date"
                                    id="endDate"
                                    className="form-control border-primary"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="clientFilter" className="form-label">
                                    <i className="bi bi-person me-2"></i>Client
                                </label>
                                <select
                                    id="clientFilter"
                                    className="form-select border-primary"
                                    value={clientFilter}
                                    onChange={(e) => setClientFilter(e.target.value)}
                                >
                                    <option value="">-- Sélectionnez un client --</option>
                                    {clients.map((client, index) => (
                                        <option key={index} value={client}>
                                            {client}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 text-end mt-3">
                                <button className="btn btn-primary px-4 py-2" onClick={handleFilter}>
                                    <i className="bi bi-funnel me-2"></i>Filtrer
                                </button>
                            </div>
                        </div>
                    </div>

                    <DataGrid
                        rows={filteredRapports}
                        columns={passageColumns.concat(actionColumn)}
                        pageSize={8}
                        rowsPerPageOptions={[8]}
                    />
                </div>
            )}
        </>    )
}

export default DataTableRapportsPassages;