import { db } from '../firebase-config'
import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc, query, where,orderBy} from 'firebase/firestore'

const productsCollectionRef = collection(db,"products")

class ProductDataService {
    addProduct = ( newProduct ) => {
        return addDoc(productsCollectionRef,newProduct)
    }

    updateProduct = (id,updatedProduct) => {
        const productDoc = doc(db,"products",id)
        return updateDoc(productDoc, updatedProduct)
    }

    deleteProduct = (id) => {
        const productDoc = doc(db,"products",id)
        return deleteDoc(productDoc)
    }

    getAllProducts = () => {
        const sortedProducts = query (productsCollectionRef,orderBy('product'))
        return getDocs(sortedProducts)
    }
   
    getProduct = (id) => {
        const productDoc = doc(db,"products",id)
        return getDoc(productDoc)
    }

    getOnlyProduits = () => {
        const onlyProduits = query (productsCollectionRef,where('category','==','produit'))
        return getDocs(onlyProduits) 
    }

    getOnlyMateriel = () => {
        const onlyMateriel = query (productsCollectionRef,where('category','==','petit matériel'))
        return getDocs(onlyMateriel)
    }

    getOnlySacs = () => {
        const onlySacs = query (productsCollectionRef,where('category','==','sacs aspirateurs'))
        return getDocs(onlySacs)
    }

    getOnlyPapier = () => {
        const onlyPapier = query (productsCollectionRef,where('category','==','papier'))
        return getDocs(onlyPapier)
    }

    getAutres = () => {
        const autres = query (productsCollectionRef,where('category','==','autres'))
        return getDocs(autres)
    }
}

export default new ProductDataService()
