import Navigation from '../../components/navigation/Navigation'
import { Form, Button, Container, InputGroup, Alert } from 'react-bootstrap'
import './checkMateriel.scss'
import SignaturePad from 'react-signature-canvas'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'date-fns/locale/fr'
import fr from 'date-fns/locale/fr'
import { registerLocale } from 'react-datepicker'
import { useState, useEffect, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import ClientDataService from '../../services/clientsService'
import ChecksMaterielDataService from '../../services/checksMaterielServices'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import productDataService from '../../services/productsServices'
import NotificationDataService from '../../services/notificationsService'
import { serverTimestamp } from 'firebase/firestore'

registerLocale('fr', fr)

function CheckMateriel() {
    //states

    const [Cdate, setDate] = useState(new Date().toLocaleDateString("fr-FR"))
    const [client, setClient] = useState("")
    const [address, setAddress] = useState('')
    const [status, setStatus] = useState('')
    const [doneBy, setDoneBy] = useState('')
    const [prodQtys, setProdQtys] = useState([])
    const [matQtys, setMatQtys] = useState([])
    const [sacsQtys, setSacsQtys] = useState([])
    const [papiersQtys, setPapiersQtys] = useState([])
    const [autre1, setAutre1] = useState("")
    const [packing1, setPacking1] = useState('')
    const [qty1, setQty1] = useState('0')
    const [autre2, setAutre2] = useState("")
    const [packing2, setPacking2] = useState('')
    const [qty2, setQty2] = useState('0')
    const [autre3, setAutre3] = useState("")
    const [packing3, setPacking3] = useState('')
    const [qty3, setQty3] = useState('0')
    const [autre4, setAutre4] = useState("")
    const [packing4, setPacking4] = useState('')
    const [qty4, setQty4] = useState('0')
    const [autre5, setAutre5] = useState("")
    const [packing5, setPacking5] = useState('')
    const [qty5, setQty5] = useState('0')
    const [clientName, setClientName] = useState("")
    const [signature, setSignature] = useState('')
    const [deliveredBy, setDeliveredBy] = useState('')

    const [message, setMessage] = useState({ error: false, msg: "" })

    //Voir les clients pour dropdown "Choisir client"
    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    /*const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }*/

    const getClients = async () => {
        try {
            const data = await ClientDataService.getActiveClients();
            const clientsList = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));


            const sortedClients = clientsList.sort((a, b) => a.client.localeCompare(b.client));

            setClients(sortedClients);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    };




    //Accordéon : Voir les articles - produits
    const [produits, setProduits] = useState([])

    useEffect(() => {
        getProduits()

    }, [])

    const getProduits = async () => {
        const dataProduits = await productDataService.getOnlyProduits()
        setProdQtys(Array.from({ length: dataProduits.docs.length }, () => "0")); // Quantités à 0 par défaut
        setProduits(dataProduits.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    //Accordéon : Voir les articles - petit matériel 
    const [materiels, setMateriels] = useState([])

    useEffect(() => {
        getMateriels()
    }, [])

    const getMateriels = async () => {
        const dataMateriel = await productDataService.getOnlyMateriel()
        setMatQtys(Array.from({ length: dataMateriel.docs.length }, () => "0")); // Quantités à 0 par défaut
        setMateriels(dataMateriel.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    //Accordéon : Voir les articles - sacs aspirateurs
    const [sacs, setSacs] = useState([])

    useEffect(() => {
        getSacs()
    }, [])

    const getSacs = async () => {
        const dataSacs = await productDataService.getOnlySacs()
        setSacsQtys(Array.from({ length: dataSacs.docs.length }, () => "0")); // Quantités à 0 par défaut
        setSacs(dataSacs.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    //Accordéon : Voir les articles - papier 
    const [papiers, setPapiers] = useState([])

    useEffect(() => {
        getPapiers()
    }, [])

    const getPapiers = async () => {
        const dataPapiers = await productDataService.getOnlyPapier()
        setPapiersQtys(Array.from({ length: dataPapiers.docs.length }, () => "0")); // Quantités à 0 par défaut
        setPapiers(dataPapiers.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    //Signature 
    let sigCanvas = useRef({})

    const save = (event) => {
        event.preventDefault()
        const sigData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        setSignature(sigData)

    }

    const clear = () => {
        sigCanvas.current.clear()
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessage("")
        if (client === "" || prodQtys === "" || matQtys === '' || sacsQtys === '' || papiersQtys === '') {
            setMessage({ error: true, msg: "Les champs * sont obligatoires. Si une quantité est vide, entrez 0" })
            return
        }

        const newNotification = {
            type:"materiel"
        }


        const newCheckMateriel = {
            date: Cdate,
            client: client,
            address: address,
            status: status,
            doneBy: doneBy,
            produit: produits,
            prodQty: prodQtys !== '' ? prodQtys : '0',
            materiel: materiels,
            matQty: matQtys !== '' ? matQtys : '0',
            sac: sacs,
            sacQty: sacsQtys !== '' ? sacsQtys : '0',
            papier: papiers,
            papierQty: papiersQtys !== '' ? papiersQtys : '0',
            autre1: autre1,
            packing1: packing1,
            qty1: qty1,
            autre2: autre2,
            packing2: packing2,
            qty2: qty2,
            autre3: autre3,
            packing3: packing3,
            qty3: qty3,
            autre4: autre4,
            packing4: packing4,
            qty4: qty4,
            autre5: autre5,
            packing5: packing5,
            qty5: qty5,
            clientName: clientName,
            signature: signature,
            deliveredBy: deliveredBy,
            createdAt: serverTimestamp()

        }

        try {
            await NotificationDataService.addNotification(newNotification)
            await ChecksMaterielDataService.addCheckMateriel(newCheckMateriel)
            setMessage({ error: false, msg: "Le rapport a bien été envoyé" })
        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }

    }

    return (
        <div>
            <Navigation />
            <div>

                <div className="mt-5 text-center">
                    <h1>Contrôle Matériel - Produits</h1>
                    <Container className='mt-5'>
                        <Form className='m-5' onSubmit={handleSubmit}>

                            <p>* Date: </p>
                            <DatePicker

                                dateFormat='dd/MM/yyyy'
                                value={Cdate}
                                onChange={(date) => {
                                    const dateString = new Date(date).toLocaleDateString("fr-FR")
                                    setDate(dateString)
                                }}
                                locale='fr'
                                showYearDropdown
                                scrollableMonthYearDropdown
                                className='mb-5 input'
                            />

                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>* Client</InputGroup.Text>
                                <Form.Select value={client} onChange={(event) => { setClient(event.target.value) }} aria-label="Default select example">
                                    <option>Choisir un client</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={[client.client]}>{[client.client]}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>



                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>Adresse</InputGroup.Text>
                                <Form.Control type="Texte" value={address} onChange={(event) => { setAddress(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Status</InputGroup.Text>
                                <Form.Select aria-label="Default select example" value={status} onChange={(event) => { setStatus(event.target.value) }}>
                                    <option>Sélectionner un status</option>
                                    <option value="Nouveau">Nouveau client</option>
                                    <option value="Régulier">Régulier</option>
                                    <option value="GN">Grand Nettoyage</option>
                                    <option value="Test">Test</option>

                                </Form.Select>
                            </InputGroup>


                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Produits </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="container">
                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Quantité</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {produits.map((produit, index) => {

                                                        return (
                                                            <tr key={[produit.id]}>
                                                                <td value={[produit.product]}>{[produit.product]}</td>
                                                                <td>{[produit.packing]}</td>
                                                                <td className="table-primary">
                                                                    <div className="input-group">
                                                                        <input type="number" className="form-control" 
                                                                        value={prodQtys[index]} 
                                                                        onChange={(event) => setProdQtys((prev) => {
                                                                            const updatedQtys = [...prev];
                                                                            updatedQtys[index] = event.target.value; 
                                                                            return updatedQtys; 
                                                                        })}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionDetails>

                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Petit Matériel </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="container">
                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Quantité</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {materiels.map((materiel, index) => {

                                                        return (
                                                            <tr key={[materiel.id]} >
                                                                <td value={[materiel.product]}>{[materiel.product]}</td>
                                                                <td>{[materiel.packing]}</td>
                                                                <td className="table-primary">
                                                                    <div className="input-group">
                                                                        <input type="number" className="form-control" 
                                                                        value={matQtys[index]}  
                                                                        onChange={(event) => setMatQtys((prev) => {
                                                                            const updatedQtys = [...prev];
                                                                            updatedQtys[index] = event.target.value; 
                                                                            return updatedQtys; 
                                                                        })}/>
                                                                    </div>
                                                                </td>
                                                                
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionDetails>

                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Sacs Aspirateur </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="container">
                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Quantité</th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sacs.map((sac, index) => {

                                                        return (
                                                            <tr key={[sac.id]}>
                                                                <td value={[sac.product]}>{[sac.product]}</td>
                                                                <td>{[sac.packing]}</td>
                                                                <td className="table-primary">
                                                                    <div className="input-group">
                                                                        <input type="number" className="form-control" 
                                                                        value={sacsQtys[index]} 
                                                                        onChange={(event) => setSacsQtys((prev) => {
                                                                            const updatedQtys = [...prev];
                                                                            updatedQtys[index] = event.target.value; 
                                                                            return updatedQtys; 
                                                                        })}/>
                                                                    </div>
                                                                </td>
                                                                
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionDetails>

                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Papier </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="container">
                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Quantité</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {papiers.map((papier, index) => {
                                                        return (
                                                            <tr key={[papier.id]} >
                                                                <td value={[papier.product]}>{[papier.product]}</td>
                                                                <td>{[papier.packing]}</td>
                                                                <td className="table-primary">
                                                                    <div className="input-group">
                                                                        <input type="number" className="form-control" 
                                                                        value={papiersQtys[index]} 
                                                                        onChange={(event) => setPapiersQtys((prev) => {
                                                                            const updatedQtys = [...prev];
                                                                            updatedQtys[index] = event.target.value; 
                                                                            return updatedQtys; 
                                                                        })}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionDetails>

                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Autres</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="container">
                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Produit</th>
                                                        <th>Conditionnement</th>
                                                        <th>Quantité</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={autre1} onChange={(event) => { setAutre1(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={packing1} onChange={(event) => { setPacking1(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td className="table-primary">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={qty1} onChange={(event) => { setQty1(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={autre2} onChange={(event) => { setAutre2(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={packing2} onChange={(event) => { setPacking2(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td className="table-primary">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={qty2} onChange={(event) => { setQty2(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={autre3} onChange={(event) => { setAutre3(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={packing3} onChange={(event) => { setPacking3(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td className="table-primary">
                                                            <div className="input-group">
                                                                <input type="textr" className="form-control" value={qty3} onChange={(event) => { setQty3(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={autre4} onChange={(event) => { setAutre4(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={packing4} onChange={(event) => { setPacking4(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td className="table-primary">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={qty4} onChange={(event) => { setQty4(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={autre5} onChange={(event) => { setAutre5(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={packing5} onChange={(event) => { setPacking5(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        <td className="table-primary">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={qty5} onChange={(event) => { setQty5(event.target.value) }} />
                                                            </div>
                                                        </td>
                                                        
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionDetails>

                                </Accordion>
                                {/*<p style={{color: 'green'}}>* : Si une quantité est vide, entrez 0</p>*/}
                            </div>

                            <InputGroup className="mb-5 mt-5 p-3" >
                                <InputGroup.Text>Etabli par: </InputGroup.Text>
                                <Form.Control type="Texte" value={doneBy} onChange={(event) => { setDoneBy(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>Nom client </InputGroup.Text>
                                <Form.Control type="Texte" value={clientName} onChange={(event) => { setClientName(event.target.value) }} />
                            </InputGroup>

                            <p className='text-center mb-2 h5'>Signature Client ( Signez et Validez )</p>
                            <InputGroup>

                                <SignaturePad
                                    ref={sigCanvas}
                                    canvasProps={{
                                        className: "signatureCanvas"
                                    }}
                                />
                            </InputGroup>
                            <button onClick={clear} className="mb-5 mt-1 me-3">Effacer</button>
                            <button onClick={save} className="mb-5 mt-1">Valider</button>


                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>Livré par: </InputGroup.Text>
                                <Form.Control type="Texte" value={deliveredBy} onChange={(event) => { setDeliveredBy(event.target.value) }} />
                            </InputGroup>

                            <Button variant="primary" type="submit">
                                Enregistrer
                            </Button>
                        </Form>
                    </Container>
                    <div >

                    </div>{message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => {
                        if (message?.error) {
                            setMessage('')
                        } else {
                            window.location.reload()
                        }


                    }


                    }>{message?.msg}</Alert>)}


                </div>
            </div>
        </div>
    )
}

export default CheckMateriel