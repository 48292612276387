
import './clientListRapportPassage.scss'
import { useState, useEffect } from 'react'
import RapportsPassagesDataService from '../../../services/rapportsPassagesServices'
import { DataGrid } from '@mui/x-data-grid'
import { passageColumns } from '../../../clientsDataTable'
import { Button } from 'react-bootstrap'
import ClientRapportSingle from '../../clientsRapportsSingle/ClientRapportSingle'

const ClientListRapportPassage = ({client,goBack}) => {

   

    //Voir les rapports
    const [rapports, setRapports] = useState([])

    

    useEffect(() => {
        getRapports()
    }, [])

    const getRapports = async () => {
        const data = await RapportsPassagesDataService.getRapportsByClient(client)
        setRapports(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    const actionColumn = [{
        field: "action",
        headerName: "",
        width: 250,
        renderCell: (params) => {
            return (
                <div className='cellAction'>

                    <Button className="btn btn-warning rounded button" onClick={(e) => handleGetRapportId(params.row.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="VOIR DETAIL">Voir le rapport<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-search ms-2" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer-fill ms-1" viewBox="0 0 16 16">
                            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        </svg></Button>

                </div>
            )
        }
    }]

    const [rapportId, setRapportId] = useState('')

    const [open, setOpen] = useState(false)

    const handleGetRapportId = (id) => {
        setRapportId(id)
        setOpen(true)
    }

    const close = () => {
        setOpen(false)
    }


    return (
        <>
            {(open === true) ? <ClientRapportSingle id={rapportId} close={close} /> : (
                <div className="datatableRapports">
                    <h1 className="mt-5 mb-5 text-center">Rapports de Passage</h1>
                    <DataGrid
                        rows={rapports}
                        columns={passageColumns.concat(actionColumn)}
                        pageSize={8}
                        rowsPerPageOptions={[8]}

                    />
                    <Button className='m-3 button' onClick={goBack}>Retour à l'accueil</Button>
                </div>



            )}
        </>
    )
}



export default ClientListRapportPassage