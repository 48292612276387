import Navigation from '../../components/navigation/Navigation'
import { Form, Button, Container, InputGroup, FormControl, Alert, FormGroup, FormLabel } from 'react-bootstrap'
import './suviQualite.scss'
import SignaturePad from 'react-signature-canvas'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'date-fns/locale/fr'
import fr from 'date-fns/locale/fr'
import { registerLocale } from 'react-datepicker'
import { useState, useEffect, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import ClientDataService from '../../services/clientsService'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SuiviQualiteDataService from '../../services/suiviQualiteServices'
import NotificationDataService from '../../services/notificationsService'
import { serverTimestamp } from 'firebase/firestore'

registerLocale('fr', fr)



function SuiviQualite() {


    //Voir les clients pour dropdown "Choisir client"
    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    /*const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }*/

    const getClients = async () => {
        try {
            const data = await ClientDataService.getActiveClients();
            const clientsList = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));


            const sortedClients = clientsList.sort((a, b) => a.client.localeCompare(b.client));

            setClients(sortedClients);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    };

    //states

    const [Cdate, setDate] = useState(new Date().toLocaleDateString("fr-FR"))
    const [inspector, setInspector] = useState('')
    const [client, setClient] = useState("")
    const [localisation, setLocalisation] = useState('')
    const [present, setPresent] = useState('')
    const [remarks, setRemarks] = useState('')
    const [action, setAction] = useState('')
    const [clientName, setClientName] = useState('')
    const [message, setMessage] = useState({ error: false, msg: "" })

    //Accordeon - Hall d'accueil
    const [poubelleHall, setPoubelleHall] = useState(0)
    const [cendrierHall, setCendrierHall] = useState(0)
    const [portVitrHall, setPortVitrHall] = useState(0)
    const [mobilierHall, setMobilierHall] = useState(0)
    const [appuiHall, setAppuiHall] = useState(0)
    const [radiateurHall, setRadiateurHall] = useState(0)
    const [armoireHall, setArmoireHall] = useState(0)
    const [priseHall, setPriseHall] = useState(0)
    const [porteHall, setPorteHall] = useState(0)
    const [poussiereHall, setPoussiereHall] = useState(0)
    const [solHall, setSolHall] = useState(0)
    const [plintheHall, setPlintheHall] = useState(0)
    const [totalHall, setTotalHall] = useState(0)



    //Accordeon Réception
    const [poubelleReception, setPoubelleReception] = useState(0)
    const [bureauReception, setBureauReception] = useState(0)
    const [phoneReception, setPhoneReception] = useState(0)
    const [objetReception, setObjetReception] = useState(0)
    const [siegeReception, setSiegeReception] = useState(0)
    const [appuiReception, setAppuiReception] = useState(0)
    const [radiateurReception, setRadiateurReception] = useState(0)
    const [armoireReception, setArmoireReception] = useState(0)
    const [priseReception, setPriseReception] = useState(0)
    const [porteReception, setPorteReception] = useState(0)
    const [poussiereReception, setPoussiereReception] = useState(0)
    const [solReception, setSolReception] = useState(0)
    const [plintheReception, setPlintheReception] = useState(0)
    const [totalReception, setTotalReception] = useState(0)

    //Accordeon Bureaux
    const [poubelleBureau, setPoubelleBureau] = useState(0)
    const [bureauBureau, setBureauBureau] = useState(0)
    const [phoneBureau, setPhoneBureau] = useState(0)
    const [objetBureau, setObjetBureau] = useState(0)
    const [siegeBureau, setSiegeBureau] = useState(0)
    const [appuiBureau, setAppuiBureau] = useState(0)
    const [radiateurBureau, setRadiateurBureau] = useState(0)
    const [armoireBureau, setArmoireBureau] = useState(0)
    const [priseBureau, setPriseBureau] = useState(0)
    const [porteBureau, setPorteBureau] = useState(0)
    const [poussiereBureau, setPoussiereBureau] = useState(0)
    const [solBureau, setSolBureau] = useState(0)
    const [plintheBureau, setPlintheBureau] = useState(0)
    const [totalBureaux, setTotalBureaux] = useState(0)

    //Accordeon Sanitaires
    const [lavaboSanit, setLavaboSanit] = useState(0)
    const [robinetSanit, setRobinetSanit] = useState(0)
    const [miroirSanit, setMiroirSanit] = useState(0)
    const [distributeurSanit, setDistributeurSanit] = useState(0)
    const [poubelleSanit, setPoubelleSanit] = useState(0)
    const [wcSanit, setWcSanit] = useState(0)
    const [lunetteSanit, setLunetteSanit] = useState(0)
    const [urinoirSanit, setUrinoirSanit] = useState(0)
    const [doucheSanit, setDoucheSanit] = useState(0)
    const [cloisonSanit, setCloisonSanit] = useState(0)
    const [radiateurSanit, setRadiateurSanit] = useState(0)
    const [solSanit, setSolSanit] = useState(0)
    const [plintheSanit, setPlintheSanit] = useState(0)
    const [totalSanitaires, setTotalSanitaires] = useState(0)

    //Accordeon Salles de Réunion 
    const [poubelleReunion, setPoubelleReunion] = useState(0)
    const [bureauReunion, setBureauReunion] = useState(0)
    const [phoneReunion, setPhoneReunion] = useState(0)
    const [objetReunion, setObjetReunion] = useState(0)
    const [siegeReunion, setSiegeReunion] = useState(0)
    const [appuiReunion, setAppuiReunion] = useState(0)
    const [radiateurReunion, setRadiateurReunion] = useState(0)
    const [armoireReunion, setArmoireReunion] = useState(0)
    const [priseReunion, setPriseReunion] = useState(0)
    const [porteReunion, setPorteReunion] = useState(0)
    const [poussiereReunion, setPoussiereReunion] = useState(0)
    const [solReunion, setSolReunion] = useState(0)
    const [plintheReunion, setPlintheReunion] = useState(0)
    const [totalReunion, setTotalReunion] = useState(0)

    //Accordeon Douches / Vestiaires
    const [lavaboDouche, setLavaboDouche] = useState(0)
    const [robinetDouche, setRobinetDouche] = useState(0)
    const [miroirDouche, setMiroirDouche] = useState(0)
    const [distributeurDouche, setDistributeurDouche] = useState(0)
    const [poubelleDouche, setPoubelleDouche] = useState(0)
    const [wcDouche, setWcDouche] = useState(0)
    const [lunetteDouche, setLunetteDouche] = useState(0)
    const [urinoirDouche, setUrinoirDouche] = useState(0)
    const [doucheDouche, setDoucheDouche] = useState(0)
    const [cloisonDouche, setCloisonDouche] = useState(0)
    const [radiateurDouche, setRadiateurDouche] = useState(0)
    const [solDouche, setSolDouche] = useState(0)
    const [plintheDouche, setPlintheDouche] = useState(0)
    const [totalDouche, setTotalDouche] = useState(0)

    //Accordeon Réfectoire 
    const [solRefect, setSolRefect] = useState(0)
    const [bordRefect, setBordRefect] = useState(0)
    const [porteRefect, setPorteRefect] = useState(0)
    const [tableRefect, setTableRefect] = useState(0)
    const [chaiseRefect, setChaiseRefect] = useState(0)
    const [depoussierageRefect, setDepoussierageRefect] = useState(0)
    const [totalRefect, setTotalRefect] = useState(0)

    //Accordeon Cuisine 
    const [solCuisine, setSolCuisine] = useState(0)
    const [bordCuisine, setBordCuisine] = useState(0)
    const [porteCuisine, setPorteCuisine] = useState(0)
    const [tableCuisine, setTableCuisine] = useState(0)
    const [chaiseCuisine, setChaiseCuisine] = useState(0)
    const [depoussierageCuisine, setDepoussierageCuisine] = useState(0)
    const [totalCuisine, setTotalCuisine] = useState(0)

    //Accordeon Circulations
    const [solCirculation, setSolCirculation] = useState(0)
    const [bordCirculation, setBordCirculation] = useState(0)
    const [porteCirculation, setPorteCirculation] = useState(0)
    const [cadreCirculation, setCadreCirculation] = useState(0)
    const [priseCirculation, setPriseCirculation] = useState(0)
    const [plintheCirculation, setPlintheCirculation] = useState(0)
    const [totalCirculation, setTotalCirculation] = useState(0)

    //Accordeon Ascenseur
    const [solAscenseur, setSolAscenseur] = useState(0)
    const [bordAscenseur, setBordAscenseur] = useState(0)
    const [porteAscenseur, setPorteAscenseur] = useState(0)
    const [miroirAscenseur, setMiroirAscenseur] = useState(0)
    const [parIntAscenseur, setParIntAscenseur] = useState(0)
    const [parExtAscenseur, setParExtAscenseur] = useState(0)
    const [depoussierageAscenseur, setDepoussierageAscenseur] = useState(0)
    const [totalAscenseur, setTotalAscenseur] = useState(0)

    //Accordeon Escaliers
    const [solEscalier, setSolEscalier] = useState(0)
    const [bordEscalier, setBordEscalier] = useState(0)
    const [porteEscalier, setPorteEscalier] = useState(0)
    const [cadreEscalier, setCadreEscalier] = useState(0)
    const [rampeEscalier, setRampeEscalier] = useState(0)
    const [totalEscalier, setTotalEscalier] = useState(0)

    //Accordeon Parking
    const [visuelParking, setVisuelParking] = useState(0)
    const [poubelleParking, setPoubelleParking] = useState(0)
    const [totalParking, setTotalParking] = useState(0)

    //Accordeon Local de Nettoyage / Chariots / Produits
    const [local, setLocal] = useState(0)
    const [chariot, setChariot] = useState(0)
    const [stock, setStock] = useState(0)
    const [totalLocal, setTotalLocal] = useState(0)

    //total Evaluation générale
    const [totalEval, setTotalEval] = useState(0)

    //non apllicable hall
    const [naPoubelleHall, setNaPoubelleHall] = useState(0)
    const [naCendrierHall, setNaCendrierHall] = useState(0)
    const [naPortVitrHall, setNaPortVitrHall] = useState(0)
    const [naMobilierHall, setNaMobilierHall] = useState(0)
    const [naAppuiHall, setNaAppuiHall] = useState(0)
    const [naRadiateurHall, setNaRadiateurHall] = useState(0)
    const [naArmoireHall, setNaArmoireHall] = useState(0)
    const [naPriseHall, setNaPriseHall] = useState(0)
    const [naPorteHall, setNaPorteHall] = useState(0)
    const [naPoussiereHall, setNaPoussiereHall] = useState(0)
    const [naSolHall, setNaSolHall] = useState(0)
    const [naPlintheHall, setNaPlintheHall] = useState(0)

    //handleHall functions 
    const handleNaHall = () => {
        setNaPoubelleHall(5)
        setNaCendrierHall(5)
        setNaPortVitrHall(5)
        setNaMobilierHall(5)
        setNaAppuiHall(5)
        setNaRadiateurHall(5)
        setNaArmoireHall(5)
        setNaPriseHall(5)
        setNaPorteHall(5)
        setNaPoussiereHall(5)
        setNaSolHall(5)
        setNaPlintheHall(5)
    }


    const handlePoubelleHall = (e) => {
        setNaPoubelleHall(0)
        setPoubelleHall(e.target.value)
    }

    const handleCendrierHall = (e) => {
        setNaCendrierHall(0)
        setCendrierHall(e.target.value)
    }

    const handlePortVitrHall = (e) => {
        setNaPortVitrHall(0)
        setPortVitrHall(e.target.value)
    } 

    const handleMobilierHall = (e) => {
        setNaMobilierHall(0)
        setMobilierHall(e.target.value)
    }

    const handleAppuiHall = (e) => {
        setNaAppuiHall(0)
        setAppuiHall(e.target.value)
    }

    const handleRadiateurHall = (e) => {
        setNaRadiateurHall(0)
        setRadiateurHall(e.target.value)
    }

    const handleArmoireHall = (e) => {
        setNaArmoireHall(0)
        setArmoireHall(e.target.value)
    }

    const handlePriseHall = (e) => {
        setNaPriseHall(0)
        setPriseHall(e.target.value)
    }

    const handlePorteHall = (e) => {
        setNaPorteHall(0)
        setPorteHall(e.target.value)
    }
    
    const handlePoussiereHall = (e) => {
        setNaPoussiereHall(0)
        setPoussiereHall(e.target.value)
    }

    const handleSolHall = (e) => {
        setNaSolHall(0)
        setSolHall(e.target.value)
    }

    const handlePlintheHall = (e) => {
        setNaPlintheHall(0)
        setPlintheHall(e.target.value)
    }

    //non applicable Reception
    const [naPoubelleRecept, setNaPoubelleRecept] = useState(0)
    const [naBureauRecept, setNaBureauRecept] = useState(0)
    const [naTelRecept, setNaTelRecept] = useState(0)
    const [naObjRecept, setNaObjRecept] = useState(0)
    const [naSiegeRecept, setNaSiegeRecept] = useState(0)
    const [naAppuiRecept, setNaAppuiRecept] = useState(0)
    const [naRadiateurRecept, setNaRadiateurRecept] = useState(0)
    const [naArmoireRecept, setNaArmoireRecept] = useState(0)
    const [naPriseRecept, setNaPriseRecept] = useState(0)
    const [naPorteRecept, setNaPorteRecept] = useState(0)
    const [naPoussiereRecept, setNaPoussiereRecept] = useState(0)
    const [naSolRecept, setNaSolRecept] = useState(0)
    const [naPlintheRecept, setNaPlintheRecept] = useState(0)

    //handleReception functions
    const handleNaReception = () => {
        setNaPoubelleRecept(5)
        setNaBureauRecept(5)
        setNaTelRecept(5)
        setNaObjRecept(5)
        setNaSiegeRecept(5)
        setNaAppuiRecept(5)
        setNaRadiateurRecept(5)
        setNaArmoireRecept(5)
        setNaPriseRecept(5)
        setNaPorteRecept(5)
        setNaPoussiereRecept(5)
        setNaSolRecept(5)
        setNaPlintheRecept(5)
    }

    const handlePoubelleRecept = (e) => {
        setNaPoubelleRecept(0)
        setPoubelleReception(e.target.value)
    }

    const handleBureauRecept = (e) => {
        setNaBureauRecept(0)
        setBureauReception(e.target.value)
    }

    const handleTelRecept = (e) => {
        setNaTelRecept(0)
        setPhoneReception(e.target.value)
    }

    const handleObjRecept = (e) => {
        setNaObjRecept(0)
        setObjetReception(e.target.value)
    }

    const handleSiegeRecept = (e) => {
        setNaSiegeRecept(0)
        setSiegeReception(e.target.value)
    }

    const handleAppuiRecept = (e) => {
        setNaAppuiRecept(0)
        setAppuiReception(e.target.value)
    }

    const handleRadiateurRecept = (e) => {
        setNaRadiateurRecept(0)
        setRadiateurReception(e.target.value)
    }

    const handleArmoireRecept = (e) => {
        setNaArmoireRecept(0)
        setArmoireReception(e.target.value)
    }

    const handlePriseRecept = (e) => {
        setNaPriseRecept(0)
        setPriseReception(e.target.value)
    }

    const handlePorteRecept = (e) => {
        setNaPorteRecept(0)
        setPorteReception(e.target.value)
    }

    const handlePoussiereRecept = (e) => {
        setNaPoussiereRecept(0)
        setPoussiereReception(e.target.value)
    }

    const handleSolRecept = (e) => {
        setNaSolRecept(0)
        setSolReception(e.target.value)
    }

    const handlePlintheRecept = (e) => {
        setNaPlintheRecept(0)
        setPlintheReception(e.target.value)
    }


    //non applicable Bureaux
    const [naPoubelleBureau, setNaPoubelleBureau] = useState(0)
    const [naBureauBureau, setNaBureauBureau] = useState(0)
    const [naPhoneBureau, setNaPhoneBureau] = useState(0)
    const [naObjBureau, setNaObjBureau] = useState(0)
    const [naSiegeBureau, setNaSiegeBureau] = useState(0)
    const [naAppuiBureau, setNaAppuiBureau] = useState(0)
    const [naRadiateurBureau, setNaRadiateurBureau] = useState(0)
    const [naArmoireBureau, setNaArmoireBureau] = useState(0)
    const [naPriseBureau, setNaPriseBureau] = useState(0)
    const [naPorteBureau, setNaPorteBureau] = useState(0)
    const [naPoussiereBureau,setNaPoussiereBureau] = useState(0)
    const [naSolBureau, setNaSolBureau] = useState(0)
    const [naPlinthebureau, setNaPlintheBureau] = useState(0)

    //handleBureau functions
    const handleNaBureau = () => {
        setNaPoubelleBureau(5)
        setNaBureauBureau(5)
        setNaPhoneBureau(5)
        setNaObjBureau(5)
        setNaSiegeBureau(5)
        setNaAppuiBureau(5)
        setNaRadiateurBureau(5)
        setNaArmoireBureau(5)
        setNaPriseBureau(5)
        setNaPorteBureau(5)
        setNaPoussiereBureau(5)
        setNaSolBureau(5)
        setNaPlintheBureau(5)
    }

    const handlePoubelleBureau = (e) => {
        setNaPoubelleBureau(0)
        setPoubelleBureau(e.target.value)
    }

    const handleBureauBureau = (e) => {
        setNaBureauBureau(0)
        setBureauBureau(e.target.value)
    }

    const handlePhoneBureau = (e) => {
        setNaPhoneBureau(0)
        setPhoneBureau(e.target.value)
    }

    const handleObjBureau = (e) => {
        setNaObjBureau(0)
        setObjetBureau(e.target.value)
    }

    const handleSiegeBureau = (e) => {
        setNaSiegeBureau(0)
        setSiegeBureau(e.target.value)
    }

    const handleAppuiBureau = (e) => {
        setNaAppuiBureau(0)
        setAppuiBureau(e.target.value)
    }

    const handleRadiateurBureau = (e) => {
        setNaRadiateurBureau(0)
        setRadiateurBureau(e.target.value)
    }

    const handleArmoireBureau = (e) => {
        setNaArmoireBureau(0)
        setArmoireBureau(e.target.value)
    }

    const handlePriseBureau = (e) => {
        setNaPriseBureau(0)
        setPriseBureau(e.target.value)
    }

    const handlePorteBureau = (e) => {
        setNaPorteBureau(0)
        setPorteBureau(e.target.value)
    }

    const handlePoussiereBureau = (e) => {
        setNaPoussiereBureau(0)
        setPoussiereBureau(e.target.value)
    }

    const handleSolBureau = (e) => {
        setNaSolBureau(0)
        setSolBureau(e.target.value)
    }

    const handlePlintheBureau = (e) => {
        setNaPlintheBureau(0)
        setPlintheBureau(e.target.value)
    }

    //non applicable Sanitaires
    const [naLavabSanit, setNaLavabSanit] = useState(0)
    const [naRobinSanit, setNaRobinSanit] = useState(0)
    const [naMiroirSanit, setNaMiroirSanit] = useState(0)
    const [naDistrSanit, setNaDistrSanit] = useState(0)
    const [naPoubelleSanit, setNaPoubelleSanit] = useState(0)
    const [naWcSanit, setNaWcSanit] = useState(0)
    const [naLunSanit, setNaLunSanit] = useState(0)
    const [naUrinoirSanit, setNaUrinoirSanit] = useState(0)
    const [naDoucheSanit, setNaDoucheSanit] = useState(0)
    const [naCloisonSanit, setNaCloisonSanit] = useState(0)
    const [naRadiateurSanit, setNaRaditeurSanit] = useState(0)
    const [naSolSanit, setNaSolSanit] = useState(0)
    const [naPlintheSanit, setNaPlintheSanit] = useState(0)

    //handleSanitaires functions
    const handleNaSanit = () => {
        setNaLavabSanit(5)
        setNaRobinSanit(5)
        setNaMiroirSanit(5)
        setNaDistrSanit(5)
        setNaPoubelleSanit(5)
        setNaWcSanit(5)
        setNaLunSanit(5)
        setNaUrinoirSanit(5)
        setNaDoucheSanit(5)
        setNaCloisonSanit(5)
        setNaRaditeurSanit(5)
        setNaSolSanit(5)
        setNaPlintheSanit(5)
    }

    const handleLavabSanit = (e) => {
        setNaLavabSanit(0)
        setLavaboSanit(e.target.value)
    }

    const handleRobinSanit = (e) => {
        setNaRobinSanit(0)
        setRobinetSanit(e.target.value)
    }

    const handleMiroirSanit = (e) => {
        setNaMiroirSanit(0)
        setMiroirSanit(e.target.value)
    }

    const handleDistrSanit = (e) => {
        setNaDistrSanit(0)
        setDistributeurSanit(e.target.value)
    }

    const handlePoubelleSanit = (e) => {
        setNaPoubelleSanit(0)
        setPoubelleSanit(e.target.value)
    }

    const handleWcSanit = (e) => {
        setNaWcSanit(0)
        setWcSanit(e.target.value)
    }

    const handleLunSanit = (e) => {
        setNaLunSanit(0)
        setLunetteSanit(e.target.value)
    }

    const handleUrinoirSanit = (e) => {
        setNaUrinoirSanit(0)
        setUrinoirSanit(e.target.value)
    }

    const handleDoucheSanit = (e) => {
        setNaDoucheSanit(0)
        setDoucheSanit(e.target.value)
    }

    const handleCloisonSanit = (e) => {
        setNaCloisonSanit(0)
        setCloisonSanit(e.target.value)
    }

    const handleRadiateurSanit = (e) => {
        setNaRaditeurSanit(0)
        setRadiateurSanit(e.target.value)
    }

    const handleSolSanit = (e) => {
        setNaSolSanit(0)
        setSolSanit(e.target.value)
    }

    const handlePlintheSanit = (e) => {
        setNaPlintheSanit(0)
        setPlintheSanit(e.target.value)
    }

    //non applicable Reunion
    const [naPoubelleReunion, setNaPoubelleReunion] = useState(0)
    const [naBureauReunion, setNaBureauReunion] = useState(0)
    const [naPhoneReunion, setNaPhoneReunion] = useState(0)
    const [naObjReunion, setNaObjReunion] = useState(0)
    const [naSiegeReunion, setNaSiegeReunion] = useState(0)
    const [naAppuiReunion, setNaAppuiReunion] = useState(0)
    const [naRadiateurReunion, setNaRadiateurReunion] = useState(0)
    const [naArmoireReunion, setNaArmoireReunion] = useState(0)
    const [naPriseReunion, setNaPriseReunion] = useState(0)
    const [naPorteReunion, setNaPorteReunion] = useState(0)
    const [naPoussiereReunion, setNaPoussiereReunion] = useState(0)
    const [naSolReunion, setNaSolReunion] = useState(0)
    const [naPlintheReunion, setNaPlintheReunion] = useState(0)

    //handleReunion functions
    const handleNaReunion = () => {
        setNaPoubelleReunion(5)
        setNaBureauReunion(5)
        setNaPhoneReunion(5)
        setNaObjReunion(5)
        setNaSiegeReunion(5)
        setNaAppuiReunion(5)
        setNaRadiateurReunion(5)
        setNaArmoireReunion(5)
        setNaPriseReunion(5)
        setNaPorteReunion(5)
        setNaPoussiereReunion(5)
        setNaSolReunion(5)
        setNaPlintheReunion(5)
    }

    const handlePoubelleReunion = (e) => {
        setNaPoubelleReunion(0)
        setPoubelleReunion(e.target.value)
    }

    const handleBureauReunion = (e) => {
        setNaBureauReunion(0)
        setBureauReunion(e.target.value)
    }

    const handlePhoneReunion = (e) => {
        setNaPhoneReunion(0)
        setPhoneReunion(e.target.value)
    }

    const handleObjReunion = (e) => {
        setNaObjReunion(0)
        setObjetReunion(e.target.value)
    }

    const handleSiegeReunion = (e) => {
        setNaSiegeReunion(0)
        setSiegeReunion(e.target.value)
    }

    const handleAppuiReunion = (e) => {
        setNaAppuiReunion(0)
        setAppuiReunion(e.target.value)
    }

    const handleRadiateurReunion = (e) => {
        setNaRadiateurReunion(0)
        setRadiateurReunion(e.target.value)
    }

    const handleArmoirReunion = (e) => {
        setNaArmoireReunion(0)
        setArmoireReunion(e.target.value)
    }

    const handlePriseReunion = (e) => {
        setNaPriseReunion(0)
        setPriseReunion(e.target.value)
    }

    const handlePorteReunion = (e) => {
        setNaPorteReunion(0)
        setPorteReunion(e.target.value)
    }

    const handlePoussiereReunion = (e) => {
        setNaPoussiereReunion(0)
        setPoussiereReunion(e.target.value)
    }

    const handleSolReunion = (e) => {
        setNaSolReunion(0)
        setSolReunion(e.target.value)
    }

    const handlePlintheReunion = (e) => {
        setNaPlintheReunion(0)
        setPlintheReunion(e.target.value)
    }

    //non applicable Douches
    const [naLavabDouche, setNaLavabDouche] = useState(0)
    const [naRobinDouche, setNaRobinDouche] = useState(0)
    const [naMiroirDouche,setNaMiroirDouche] = useState(0)
    const [naDistrDouche, setNaDistrDouche] = useState(0)
    const [naPoubelleDouche, setNaPoubelleDouche] = useState(0)
    const [naWcDouche, setNaWcDouche] = useState(0)
    const [naLunDouche, setNaLunDouche] = useState(0)
    const [naUrinoirDouche, setNaUrinoirDouche] = useState(0)
    const [naDoucheDouche, setNaDoucheDouche] = useState(0)
    const [naCloisonDouche, setNaCloisonDouche] = useState(0)
    const [naRadiateurDouche, setNaRadiateurDouche] = useState(0)
    const [naSolDouche, setNaSolDouche] = useState(0)
    const [naPlintheDouche, setNaPlintheDouche] = useState(0)

    //handleDouche functions
    const handleNaDouche = () => {
        setNaLavabDouche(5)
        setNaRobinDouche(5)
        setNaMiroirDouche(5)
        setNaDistrDouche(5)
        setNaPoubelleDouche(5)
        setNaWcDouche(5)
        setNaLunDouche(5)
        setNaUrinoirDouche(5)
        setNaDoucheDouche(5)
        setNaCloisonDouche(5)
        setNaRadiateurDouche(5)
        setNaSolDouche(5)
        setNaPlintheDouche(5)
    }

    const handleLavabDouche = (e) => {
        setNaLavabDouche(0)
        setLavaboDouche(e.target.value)
    }

    const handleRobinDouche = (e) => {
        setNaRobinDouche(0)
        setRobinetDouche(e.target.value)
    }

    const handleMiroirDouche = (e) => {
        setNaMiroirDouche(0)
        setMiroirDouche(e.target.value)
    }

    const handleDistrDouche = (e) => {
        setNaDistrDouche(0)
        setDistributeurDouche(e.target.value)
    }

    const handlePoubelleDouche = (e) => {
        setNaPoubelleDouche(0)
        setPoubelleDouche(e.target.value)
    }

    const handleWcDouche = (e) => {
        setNaWcDouche(0)
        setWcDouche(e.target.value)
    }

    const handleLunDouche = (e) => {
        setNaLunDouche(0)
        setLunetteDouche(e.target.value)
    }

    const handleUrinoirDouche = (e) => {
        setNaUrinoirDouche(0)
        setUrinoirDouche(e.target.value)
    }

    const handleDoucheDouche = (e) => {
        setNaDoucheDouche(0)
        setDoucheDouche(e.target.value)
    }

    const handleCloisonDouche = (e) => {
        setNaCloisonDouche(0)
        setCloisonDouche(e.target.value)
    }

    const handleRadiateurDouche = (e) => {
        setNaRadiateurDouche(0)
        setRadiateurDouche(e.target.value)
    }

    const handleSolDouche = (e) => {
        setNaSolDouche(0)
        setSolDouche(e.target.value)
    }

    const handlePlintheDouche = (e) => {
        setNaPlintheDouche(0)
        setPlintheDouche(e.target.value)
    }

    //non applicable Refectoire
    
    const [naSolRefect, setNaSolRefect] = useState(0)
    const [naBordRefect, setNaBordRefect] = useState(0)
    const [naPorteRefect, setNaPorteRefect] = useState(0)
    const [naTableRefect, setNaTableRefect] = useState(0)
    const [naChaiseRefect, setNaChaiseRefect] = useState(0)
    const [naDepoussRefect, setNaDepoussRefect] = useState(0)

    //handleRefectoire funcions
    const handleNaRefect = () => {
        setNaSolRefect(5)
        setNaBordRefect(5)
        setNaPorteRefect(5)
        setNaTableRefect(5)
        setNaChaiseRefect(5)
        setNaDepoussRefect(5)
    }

    const handleSolRefect = (e) => {
        setNaSolRefect(0)
        setSolRefect(e.target.value)
    }

    const handleBordRefect = (e) => {
        setNaBordRefect(0)
        setBordRefect(e.target.value)
    }

    const handlePorteRefect = (e) => {
        setNaPorteRefect(0)
        setPorteRefect(e.target.value)
    }

    const handleTableRefect = (e) => {
        setNaTableRefect(0)
        setTableRefect(e.target.value)
    }

    const handleChaiseRefect = (e) => {
        setNaChaiseRefect(0)
        setChaiseRefect(e.target.value)
    }

    const handleDepoussRefect = (e) => {
        setNaDepoussRefect(0)
        setDepoussierageRefect(e.target.value)
    }


    //non applicable Cuisine
    const [naSolCuisine, setNaSolCuisine] = useState(0)
    const [naBordCuisine, setNaBordCuisine] = useState(0)
    const [naPorteCuisine, setNaPorteCuisine] = useState(0)
    const [naTableCuisine, setNaTableCuisine] = useState(0)
    const [naChaiseCuisine, setNaChaiseCuisine] = useState(0)
    const [naDepoussCuisine, setNaDepoussCuisine] = useState(0)

    //handleCuisine functions
    const handleNaCuisine = () => {
        setNaSolCuisine(5)
        setNaBordCuisine(5)
        setNaPorteCuisine(5)
        setNaTableCuisine(5)
        setNaChaiseCuisine(5)
        setNaDepoussCuisine(5)
    }

    const handleSolCuisine = (e) => {
        setNaSolCuisine(0)
        setSolCuisine(e.target.value)
    }

    const handleBordCuisine = (e) => {
        setNaBordCuisine(0)
        setBordCuisine(e.target.value)
    }

    const handlePorteCuisine = (e) => {
        setNaPorteCuisine(0)
        setPorteCuisine(e.target.value)
    }

    const handleTableCuisine = (e) => {
        setNaTableCuisine(0)
        setTableCuisine(e.target.value)
    }

    const handleChaiseCuisine = (e) => {
        setNaChaiseCuisine(0)
        setChaiseCuisine(e.target.value)
    }

    const handleDepoussCuisine = (e) => {
        setNaDepoussCuisine(0)
        setDepoussierageCuisine(e.target.value)
    }

    //non applicable Circulations
    const [naSolCircul, setNaSolCircul] = useState(0)
    const [naBordCircul, setNaBordCircul] = useState(0)
    const [naPorteCircul, setNaPorteCircul] = useState(0)
    const [naCadreCircul, setNaCadreCircul] = useState(0)
    const [naPriseCircul, setNaPriseCircul] = useState(0)
    const [naPlintheCircul, setNaPlintheCircul] = useState(0)

    //handleCirculations functions
    const handleNaCircul = () => {
        setNaSolCircul(5)
        setNaBordCircul(5)
        setNaPorteCircul(5)
        setNaCadreCircul(5)
        setNaPriseCircul(5)
        setNaPlintheCircul(5)
    }

    const handleSolCircul = (e) => {
        setNaSolCircul(0)
        setSolCirculation(e.target.value)
    }

    const handleBordCircul = (e) => {
        setNaBordCircul(0)
        setBordCirculation(e.target.value)
    }

    const handlePorteCircul = (e) => {
        setNaPorteCircul(0)
        setPorteCirculation(e.target.value)
    }

    const handleCadreCircul = (e) => {
        setNaCadreCircul(0)
        setCadreCirculation(e.target.value)
    }

    const handlePriseCircul = (e) => {
        setNaPriseCircul(0)
        setPriseCirculation(e.target.value)
    }

    const handlePlintheCircul = (e) => {
        setNaPlintheCircul(0)
        setPlintheCirculation(e.target.value)
    }

    //non applicable Ascenseur
    const [naSolAscens, setNaSolAscens] = useState(0)
    const [naBordAscens, setNaBordAscens] = useState(0)
    const [naPorteAscens, setNaPorteAscens] = useState(0)
    const [naMiroirAscens, setNaMiroirAscens] = useState(0)
    const [naParoiIntAscens, setNaParoiIntAscens] = useState(0)
    const [naParoiExtAscens, setNaParoiExtAscens] = useState(0)
    const [naDepoussAscens, setNaDepoussAscens] = useState(0)

    //handleAscenseur functions
    const handleNaAscenseur = () => {
        setNaSolAscens(5)
        setNaBordAscens(5)
        setNaPorteAscens(5)
        setNaMiroirAscens(5)
        setNaParoiIntAscens(5)
        setNaParoiExtAscens(5)
        setNaDepoussAscens(5)
    }

    const handleSolAscenseur = (e) => {
        setNaSolAscens(0)
        setSolAscenseur(e.target.value)
    }

    const handleBordAscenseur = (e) => {
        setNaBordAscens(0)
        setBordAscenseur(e.target.value)
    }

    const handlePorteAscenseur = (e) => {
        setNaPorteAscens(0)
        setPorteAscenseur(e.target.value)
    }

    const handleMiroirAscenseur = (e) => {
        setNaMiroirAscens(0)
        setMiroirAscenseur(e.target.value)
    }

    const handleParoiIntAscenseur = (e) => {
        setNaParoiIntAscens(0)
        setParIntAscenseur(e.target.value)
    }

    const handleParoiExtAscenseur = (e) => {
        setNaParoiExtAscens(0)
        setParExtAscenseur(e.target.value)
    }

    const handleDepoussAscenseur = (e) => {
        setNaDepoussAscens(0)
        setDepoussierageAscenseur(e.target.value)
    }


    //non applicable Escaliers
    const [naSolEscal, setNaSolEscal] = useState(0)
    const [naBordEscal, setNaBordEscal] = useState(0)
    const [naPorteEscal, setNaPorteEscal] = useState(0)
    const [naCadreEscal, setNaCadreEscal] = useState(0)
    const [naRampeEscal, setNaRampeEscal] = useState(0)

    //handleEscaliers functions
    const handleNaEscaliers = () => {
        setNaSolEscal(5)
        setNaBordEscal(5)
        setNaPorteEscal(5)
        setNaCadreEscal(5)
        setNaRampeEscal(5)
    }

    const handleSolEscal = (e) => {
        setNaSolEscal(0)
        setSolEscalier(e.target.value)
    }

    const handleBordEscal = (e) => {
        setNaBordEscal(0)
        setBordEscalier(e.target.value)
    }

    const handlePorteEscal = (e) => {
        setNaPorteEscal(0)
        setPorteEscalier(e.target.value)
    }

    const handleCadreEscal = (e) => {
        setNaCadreEscal(0)
        setCadreEscalier(e.target.value)
    }

    const handleRampeEscal = (e) => {
        setNaRampeEscal(0)
        setRampeEscalier(e.target.value)
    }


    //non applicable Parking
    const [naVisuelParking, setNaVisuelParking] = useState(0)
    const [naPoubelleParking, setNaPoubelleParking] = useState(0)

    //handleParking functions
    const handleNaParking = () => {
        setNaVisuelParking(5)
        setNaPoubelleParking(5)
    }

    const handleVisuelParking = (e) => {
        setNaVisuelParking(0)
        setVisuelParking(e.target.value)
    }

    const handlePoubelleParking = (e) => {
        setNaPoubelleParking(0)
        setPoubelleParking(e.target.value)
    }

    //non applicable Local
    const [naLocalLocal, setNaLocalLocal] = useState(0)
    const [naChariotLocal, setNaChariotLocal] = useState(0)
    const [naStockLocal, setNaStockLocal] = useState(0)

    //handleLocal functions
    const handleNaLocal = () => {
        setNaLocalLocal(5)
        setNaChariotLocal(5)
        setNaStockLocal(5)
    }

    const handleLocal = (e) => {
        setNaLocalLocal(0)
        setLocal(e.target.value)
    }

    const handleChariot = (e) => {
        setNaChariotLocal(0)
        setChariot(e.target.value)
    }

    const handleStock = (e) => {
        setNaStockLocal(0)
        setStock(e.target.value)
    }


    //Signature 
    let sigCanvas = useRef({})
    const [signature, setSignature] = useState('')

    const save = (event) => {
        event.preventDefault()
        const sigData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        setSignature(sigData)

    }

    const clear = () => {
        sigCanvas.current.clear()
    }

    //total Hall
    useEffect(() => {
        setTotalHall(parseInt(poubelleHall) + parseInt(cendrierHall) + parseInt(portVitrHall) + parseInt(mobilierHall) + parseInt(appuiHall) + parseInt(radiateurHall) + parseInt(armoireHall) + parseInt(priseHall) + parseInt(porteHall) + parseInt(poussiereHall) + parseInt(solHall) + parseInt(plintheHall))
    }, [poubelleHall, cendrierHall, portVitrHall, mobilierHall, appuiHall, radiateurHall, armoireHall, priseHall, porteHall, poussiereHall, solHall, plintheHall])

    const naHallTotal = naPoubelleHall + naCendrierHall + naPortVitrHall + naMobilierHall + naAppuiHall + naRadiateurHall + naArmoireHall + naPriseHall + naPorteHall + naPoussiereHall + naSolHall + naPlintheHall

    const hallTotal = 60 - naHallTotal



    //total Réception
    useEffect(() => {
        setTotalReception(parseInt(poubelleReception) + parseInt(bureauReception) + parseInt(phoneReception) + parseInt(objetReception) + parseInt(siegeReception) + parseInt(appuiReception) + parseInt(radiateurReception) + parseInt(armoireReception) + parseInt(priseReception) + parseInt(porteReception) + parseInt(poussiereReception) + parseInt(solReception) + parseInt(plintheReception))
    }, [poubelleReception, bureauReception, phoneReception, objetReception, siegeReception, appuiReception, radiateurReception, armoireReception, priseReception, porteReception, poussiereReception, solReception, plintheReception])

    const naReceptTotal = naPoubelleRecept + naBureauRecept + naTelRecept + naObjRecept + naSiegeRecept + naAppuiRecept + naRadiateurRecept + naArmoireRecept + naPriseRecept + naPorteRecept + naPoussiereRecept + naSolRecept + naPlintheRecept

    const receptionTotal = 65 - naReceptTotal

    //total Bureaux  
    useEffect(() => {
        setTotalBureaux(parseInt(poubelleBureau) + parseInt(bureauBureau) + parseInt(phoneBureau) + parseInt(objetBureau) + parseInt(siegeBureau) + parseInt(appuiBureau) + parseInt(radiateurBureau) + parseInt(armoireBureau) + parseInt(priseBureau) + parseInt(porteBureau) + parseInt(poussiereBureau) + parseInt(solBureau) + parseInt(plintheBureau))
    }, [poubelleBureau, bureauBureau, phoneBureau, objetBureau, siegeBureau, appuiBureau, radiateurBureau, armoireBureau, priseBureau, porteBureau, poussiereBureau, solBureau, plintheBureau])

    const naBureauTotal = naPoubelleBureau + naBureauBureau + naPhoneBureau + naObjBureau + naSiegeBureau + naAppuiBureau + naRadiateurBureau + naArmoireBureau + naPriseBureau + naPorteBureau + naPoussiereBureau + naSolBureau + naPlinthebureau

    const bureauxTotal = 65 - naBureauTotal

    //total Sanitaires 
    useEffect(() => {
        setTotalSanitaires(parseInt(lavaboSanit) + parseInt(robinetSanit) + parseInt(miroirSanit) + parseInt(distributeurSanit) + parseInt(poubelleSanit) + parseInt(wcSanit) + parseInt(lunetteSanit) + parseInt(urinoirSanit) + parseInt(doucheSanit) + parseInt(cloisonSanit) + parseInt(radiateurSanit) + parseInt(solSanit) + parseInt(plintheSanit))
    }, [lavaboSanit, robinetSanit, miroirSanit, distributeurSanit, poubelleSanit, wcSanit, lunetteSanit, urinoirSanit, doucheSanit, cloisonSanit, radiateurSanit, solSanit, plintheSanit])

    const naSanitTotal = naLavabSanit + naRobinSanit + naMiroirSanit + naDistrSanit + naPoubelleSanit + naWcSanit + naLunSanit + naUrinoirSanit + naDoucheSanit + naCloisonSanit + naRadiateurSanit + naSolSanit + naPlintheSanit

    const sanitairesTotal = 65 - naSanitTotal

    //total Salles de Réunion 
    useEffect(() => {
        setTotalReunion(parseInt(poubelleReunion) + parseInt(bureauReunion) + parseInt(phoneReunion) + parseInt(objetReunion) + parseInt(siegeReunion) + parseInt(appuiReunion) + parseInt(radiateurReunion) + parseInt(armoireReunion) + parseInt(priseReunion) + parseInt(porteReunion) + parseInt(poussiereReunion) + parseInt(solReunion) + parseInt(plintheReunion))
    }, [poubelleReunion, bureauReunion, phoneReunion, objetReunion, siegeReunion, appuiReunion, radiateurReunion, armoireReunion, priseReunion, porteReunion, poussiereReunion, solReunion, plintheReunion])

    const naReunionTotal = naPoubelleReunion + naBureauReunion + naPhoneReunion + naObjReunion + naSiegeReunion + naAppuiReunion + naRadiateurReunion + naArmoireReunion + naPriseReunion + naPorteReunion + naPoussiereReunion + naSolReunion + naPlintheReunion

    const reunionTotal = 65 - naReunionTotal

    //total Douches/Vestiaires
    useEffect(() => {
        setTotalDouche(parseInt(lavaboDouche) + parseInt(robinetDouche) + parseInt(miroirDouche) + parseInt(distributeurDouche) + parseInt(poubelleDouche) + parseInt(wcDouche) + parseInt(lunetteDouche) + parseInt(urinoirDouche) + parseInt(doucheDouche) + parseInt(cloisonDouche) + parseInt(radiateurDouche) + parseInt(solDouche) + parseInt(plintheDouche))
    }, [lavaboDouche, robinetDouche, miroirDouche, distributeurDouche, poubelleDouche, wcDouche, lunetteDouche, urinoirDouche, doucheDouche, cloisonDouche, radiateurDouche, solDouche, plintheDouche])

    const naDoucheTotal = naLavabDouche + naRobinDouche + naMiroirDouche + naDistrDouche + naPoubelleDouche + naWcDouche + naLunDouche + naUrinoirDouche + naDoucheDouche + naCloisonDouche + naRadiateurDouche + naSolDouche + naPlintheDouche

    const doucheTotal = 65 - naDoucheTotal

    //total Réfectoire 
    useEffect(() => {
        setTotalRefect(parseInt(solRefect) + parseInt(bordRefect) + parseInt(porteRefect) + parseInt(tableRefect) + parseInt(chaiseRefect) + parseInt(depoussierageRefect))
    }, [solRefect, bordRefect, porteRefect, tableRefect, chaiseRefect, depoussierageRefect])

    const naRefectTotal = naSolRefect + naBordRefect + naPorteRefect + naTableRefect + naChaiseRefect + naDepoussRefect

    const refectTotal = 30 - naRefectTotal

    //total Cuisine 
    useEffect(() => {
        setTotalCuisine(parseInt(solCuisine) + parseInt(bordCuisine) + parseInt(porteCuisine) + parseInt(tableCuisine) + parseInt(chaiseCuisine) + parseInt(depoussierageCuisine))
    }, [solCuisine, bordCuisine, porteCuisine, tableCuisine, chaiseCuisine, depoussierageCuisine])

    const naCuisineTotal = naSolCuisine + naBordCuisine + naPorteCuisine + naTableCuisine + naChaiseCuisine + naDepoussCuisine

    const cuisineTotal = 30 - naCuisineTotal

    //total Circulations 
    useEffect(() => {
        setTotalCirculation(parseInt(solCirculation) + parseInt(bordCirculation) + parseInt(porteCirculation) + parseInt(cadreCirculation) + parseInt(priseCirculation) + parseInt(plintheCirculation))
    }, [solCirculation, bordCirculation, porteCirculation, cadreCirculation, priseCirculation, plintheCirculation])

    const naCirculTotal = naSolCircul + naBordCircul + naPorteCircul + naCadreCircul + naPriseCircul + naPlintheCircul

    const circulationsTotal = 30 - naCirculTotal

    //total Ascenseur 
    useEffect(() => {
        setTotalAscenseur(parseInt(solAscenseur) + parseInt(bordAscenseur) + parseInt(porteAscenseur) + parseInt(miroirAscenseur) + parseInt(parIntAscenseur) + parseInt(parExtAscenseur) + parseInt(depoussierageAscenseur))
    }, [solAscenseur, bordAscenseur, porteAscenseur, miroirAscenseur, parIntAscenseur, parExtAscenseur, depoussierageAscenseur])

    const naAscenseurTotal = naSolAscens + naBordAscens + naPorteAscens + naMiroirAscens + naParoiIntAscens + naParoiExtAscens + naDepoussAscens

    const ascenseurTotal = 35 - naAscenseurTotal

    //total Escaliers
    useEffect(() => {
        setTotalEscalier(parseInt(solEscalier) + parseInt(bordEscalier) + parseInt(porteEscalier) + parseInt(cadreEscalier) + parseInt(rampeEscalier))
    }, [solEscalier, bordEscalier, porteEscalier, cadreEscalier, rampeEscalier])

    const naEscalTotal = naSolEscal + naBordEscal + naPorteEscal + naCadreEscal + naRampeEscal

    const escaliersTotal = 25 - naEscalTotal

    //total Parking 
    useEffect(() => {
        setTotalParking(parseInt(visuelParking) + parseInt(poubelleParking))
    }, [visuelParking, poubelleParking])

    const naParkingTotal = naVisuelParking + naPoubelleParking

    

    const parkingTotal = 10 - naParkingTotal

    //total Local de Nettoyage / Chariots / Produits
    useEffect(() => {
        setTotalLocal(parseInt(local) + parseInt(chariot) + parseInt(stock))
    }, [local, chariot, stock])

    const naLocalTotal = naLocalLocal + naChariotLocal + naStockLocal

    const localTotal = 15 - naLocalTotal

    //total Evaluation en %
    useEffect(() => {
        setTotalEval(Math.round((totalHall + totalReception + totalBureaux + totalSanitaires + totalReunion + totalDouche + totalRefect + totalCuisine + totalCirculation + totalAscenseur + totalEscalier + totalParking + totalLocal) / (560 - naHallTotal - naReceptTotal - naBureauTotal - naSanitTotal - naReunionTotal - naDoucheTotal - naRefectTotal - naCuisineTotal - naCirculTotal - naAscenseurTotal - naEscalTotal - naParkingTotal - naLocalTotal) * 100))
    }, [totalHall, totalReception, totalBureaux, totalSanitaires, totalReunion, totalDouche, totalRefect, totalCuisine, totalCirculation, totalAscenseur, totalEscalier, totalParking, totalLocal,naHallTotal,naReceptTotal,naBureauTotal,naSanitTotal,naReunionTotal,naDoucheTotal,naRefectTotal,naCuisineTotal, naCirculTotal, naAscenseurTotal,naEscalTotal,naParkingTotal, naLocalTotal])

    const perc = '%'

    //Ajouter un rapport
    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessage("")


        const newNotification = {
            type: "quality"
        }

        const newSuiviQualite = {
            date: Cdate,
            inspector: inspector,
            client: client,
            localisation: localisation,
            present: present,
            remarks: remarks,
            action: action,
            clientName: clientName,
            signature: signature,
            //Hall
            poubelleHall: poubelleHall,
            cendrierHall: cendrierHall,
            portVitrHall: portVitrHall,
            mobilierHall: mobilierHall,
            appuiHall: appuiHall,
            radiateurHall: radiateurHall,
            armoireHall: armoireHall,
            priseHall: priseHall,
            porteHall: porteHall,
            poussiereHall: poussiereHall,
            solHall: solHall,
            plintheHall: plintheHall,
            totalHall: totalHall,
            hallTotal: hallTotal,
            naHallTotal: naHallTotal,
            naPoubelleHall: naPoubelleHall,
            naCendrierHall: naCendrierHall,
            naPortVitrHall: naPortVitrHall,
            naMobilierHall: naMobilierHall,
            naAppuiHall: naAppuiHall,
            naRadiateurHall: naRadiateurHall,
            naArmoireHall: naArmoireHall,
            naPriseHall: naPriseHall,
            naPorteHall: naPorteHall,
            naPoussiereHall: naPoussiereHall,
            naSolHall: naSolHall,
            naPlintheHall: naPlintheHall,
            //Réception
            poubelleReception: poubelleReception,
            bureauReception: bureauReception,
            phoneReception: phoneReception,
            objetReception: objetReception,
            siegeReception: siegeReception,
            appuiReception: appuiReception,
            radiateurReception: radiateurReception,
            armoireReception: armoireReception,
            priseReception: priseReception,
            porteReception: porteReception,
            poussiereReception: poussiereReception,
            solReception: solReception,
            plintheReception: plintheReception,
            totalReception: totalReception,
            receptionTotal: receptionTotal,
            naReceptTotal: naReceptTotal,
            naPoubelleRecept: naPoubelleRecept,
            naBureauRecept: naBureauRecept,
            naTelRecept: naTelRecept,
            naObjRecept: naObjRecept,
            naSiegeRecept: naSiegeRecept,
            naAppuiRecept: naAppuiRecept,
            naRadiateurRecept: naRadiateurRecept,
            naArmoireRecept: naArmoireRecept,
            naPriseRecept: naPriseRecept,
            naPorteRecept: naPorteRecept,
            naPoussiereRecept: naPoussiereRecept,
            naSolRecept: naSolRecept,
            naPlintheRecept: naPlintheRecept,
            //Bureaux
            poubelleBureau: poubelleBureau,
            bureauBureau: bureauBureau,
            phoneBureau: phoneBureau,
            objetBureau: objetBureau,
            siegeBureau: siegeBureau,
            appuiBureau: appuiBureau,
            radiateurBureau: radiateurBureau,
            armoireBureau: armoireBureau,
            priseBureau: priseBureau,
            porteBureau: porteBureau,
            poussiereBureau: poussiereBureau,
            solBureau: solBureau,
            plintheBureau: plintheBureau,
            totalBureaux: totalBureaux,
            bureauxTotal: bureauxTotal,
            naBureauTotal: naBureauTotal,
            naPoubelleBureau: naPoubelleBureau,
            naBureauBureau: naBureauBureau,
            naPhoneBureau: naPhoneBureau,
            naObjBureau: naObjBureau,
            naSiegeBureau: naSiegeBureau,
            naAppuiBureau: naAppuiBureau,
            naRadiateurBureau: naRadiateurBureau,
            naArmoireBureau: naArmoireBureau,
            naPriseBureau: naPriseBureau,
            naPorteBureau: naPorteBureau,
            naPoussiereBureau: naPoussiereBureau,
            naSolBureau: naSolBureau,
            naPlinthebureau: naPlinthebureau,
            //Sanitaires
            lavaboSanit: lavaboSanit,
            robinetSanit: robinetSanit,
            miroirSanit: miroirSanit,
            distributeurSanit: distributeurSanit,
            poubelleSanit: poubelleSanit,
            wcSanit: wcSanit,
            lunetteSanit: lunetteSanit,
            urinoirSanit: urinoirSanit,
            doucheSanit: doucheSanit,
            cloisonSanit: cloisonSanit,
            radiateurSanit: radiateurSanit,
            solSanit: solSanit,
            plintheSanit: plintheSanit,
            totalSanitaires: totalSanitaires,
            sanitairesTotal: sanitairesTotal,
            naSanitTotal: naSanitTotal,
            naLavabSanit: naLavabSanit,
            naRobinSanit: naRobinSanit,
            naMiroirSanit: naMiroirSanit,
            naDistrSanit: naDistrSanit,
            naPoubelleSanit: naPoubelleSanit,
            naWcSanit: naWcSanit,
            naLunSanit: naLunSanit,
            naUrinoirSanit: naUrinoirSanit,
            naDoucheSanit: naDoucheSanit,
            naCloisonSanit: naCloisonSanit,
            naRadiateurSanit: naRadiateurSanit,
            naSolSanit: naSolSanit,
            naPlintheSanit: naPlintheSanit,
            //Salles de Réunion
            poubelleReunion: poubelleReunion,
            bureauReunion: bureauReunion,
            phoneReunion: phoneReunion,
            objetReunion: objetReunion,
            siegeReunion: siegeReunion,
            appuiReunion: appuiReunion,
            radiateurReunion: radiateurReunion,
            armoireReunion: armoireReunion,
            priseReunion: priseReunion,
            porteReunion: porteReunion,
            poussiereReunion: poussiereReunion,
            solReunion: solReunion,
            plintheReunion: plintheReunion,
            totalReunion: totalReunion,
            reunionTotal: reunionTotal,
            naReunionTotal: naReunionTotal,
            naPoubelleReunion: naPoubelleReunion,
            naBureauReunion: naBureauReunion,
            naPhoneReunion: naPhoneReunion,
            naObjReunion: naObjReunion,
            naSiegeReunion: naSiegeReunion,
            naAppuiReunion: naAppuiReunion,
            naRadiateurReunion: naRadiateurReunion,
            naArmoireReunion: naArmoireReunion,
            naPriseReunion: naPriseReunion,
            naPorteReunion: naPorteReunion,
            naPoussiereReunion: naPoussiereReunion,
            naSolReunion: naSolReunion,
            naPlintheReunion: naPlintheReunion,
            //Douches / Vestiaires
            lavaboDouche: lavaboDouche,
            robinetDouche: robinetDouche,
            miroirDouche: miroirDouche,
            distributeurDouche: distributeurDouche,
            poubelleDouche: poubelleDouche,
            wcDouche: wcDouche,
            lunetteDouche: lunetteDouche,
            urinoirDouche: urinoirDouche,
            doucheDouche: doucheDouche,
            cloisonDouche: cloisonDouche,
            radiateurDouche: radiateurDouche,
            solDouche: solDouche,
            plintheDouche: plintheDouche,
            totalDouche: totalDouche,
            doucheTotal: doucheTotal,
            naDoucheTotal: naDoucheTotal,
            naLavabDouche: naLavabDouche,
            naRobinDouche: naRobinDouche,
            naMiroirDouche: naMiroirDouche,
            naDistrDouche: naDistrDouche,
            naPoubelleDouche: naPoubelleDouche,
            naWcDouche: naWcDouche,
            naLunDouche: naLunDouche,
            naUrinoirDouche: naUrinoirDouche,
            naDoucheDouche: naDoucheDouche,
            naCloisonDouche: naCloisonDouche,
            naRadiateurDouche: naRadiateurDouche,
            naSolDouche: naSolDouche,
            naPlintheDouche: naPlintheDouche,
            //Réfectoire
            solRefect: solRefect,
            bordRefect: bordRefect,
            porteRefect: porteRefect,
            tableRefect: tableRefect,
            chaiseRefect: chaiseRefect,
            depoussierageRefect: depoussierageRefect,
            totalRefect: totalRefect,
            refectTotal: refectTotal,
            naRefectTotal: naRefectTotal,
            naSolRefect: naSolRefect,
            naBordRefect: naBordRefect,
            naPorteRefect: naPorteRefect,
            naTableRefect: naTableRefect,
            naChaiseRefect: naChaiseRefect,
            naDepoussRefect: naDepoussRefect,
            //Cuisine
            solCuisine: solCuisine,
            bordCuisine: bordCuisine,
            porteCuisine: porteCuisine,
            tableCuisine: tableCuisine,
            chaiseCuisine: chaiseCuisine,
            depoussierageCuisine: depoussierageCuisine,
            totalCuisine: totalCuisine,
            cuisineTotal: cuisineTotal,
            naCuisineTotal: naCuisineTotal,
            naSolCuisine: naSolCuisine,
            naBordCuisine: naBordCuisine,
            naPorteCuisine: naPorteCuisine,
            naTableCuisine: naTableCuisine,
            naChaiseCuisine: naChaiseCuisine,
            naDepoussCuisine: naDepoussCuisine,
            //Circulations
            solCirculation: solCirculation,
            bordCirculation: bordCirculation,
            porteCirculation: porteCirculation,
            cadreCirculation: cadreCirculation,
            priseCirculation: priseCirculation,
            plintheCirculation: plintheCirculation,
            totalCirculation: totalCirculation,
            circulationsTotal: circulationsTotal,
            naCirculTotal: naCirculTotal,
            naSolCircul: naSolCircul,
            naBordCircul: naBordCircul,
            naPorteCircul: naPorteCircul,
            naCadreCircul: naCadreCircul,
            naPriseCircul: naPriseCircul,
            naPlintheCircul: naPlintheCircul,
            //Ascenseur
            solAscenseur: solAscenseur,
            bordAscenseur: bordAscenseur,
            porteAscenseur: porteAscenseur,
            miroirAscenseur: miroirAscenseur,
            parIntAscenseur: parIntAscenseur,
            parExtAscenseur: parExtAscenseur,
            depoussierageAscenseur: depoussierageAscenseur,
            totalAscenseur: totalAscenseur,
            ascenseurTotal: ascenseurTotal,
            naAscenseurTotal: naAscenseurTotal,
            naSolAscens: naSolAscens,
            naBordAscens: naBordAscens,
            naPorteAscens: naPorteAscens,
            naMiroirAscens: naMiroirAscens,
            naParoiIntAscens: naParoiIntAscens,
            naParoiExtAscens: naParoiExtAscens,
            naDepoussAscens: naDepoussAscens,
            //Escaliers
            solEscalier: solEscalier,
            bordEscalier: bordEscalier,
            porteEscalier: porteEscalier,
            cadreEscalier: cadreEscalier,
            rampeEscalier: rampeEscalier,
            totalEscalier: totalEscalier,
            escaliersTotal: escaliersTotal,
            naEscalTotal: naEscalTotal,
            naSolEscal: naSolEscal,
            naBordEscal: naBordEscal,
            naPorteEscal: naPorteEscal,
            naCadreEscal: naCadreEscal,
            naRampeEscal: naRampeEscal,
            //Parking
            visuelParking: visuelParking,
            poubelleParking: poubelleParking,
            totalParking: totalParking,
            parkingTotal: parkingTotal,
            naParkingTotal: naParkingTotal,
            naVisuelParking: naVisuelParking,
            naPoubelleParking: naPoubelleParking,
            //Local de Nettoyage / Chariots / Produits
            local: local,
            chariot: chariot,
            stock: stock,
            totalLocal: totalLocal,
            localTotal: localTotal,
            naLocalTotal: naLocalTotal,
            naLocalLocal: naLocalLocal,
            naChariotLocal: naChariotLocal,
            naStockLocal: naStockLocal,
            //Evaluation
            totalEval: totalEval,
            //timestamp
            createdAt: serverTimestamp()
        }

        try {
            await NotificationDataService.addNotification(newNotification)
            await SuiviQualiteDataService.addSuiviQualite(newSuiviQualite)
            setMessage({ error: false, msg: "Le rapport a bien été envoyé" })
        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }
    }



    return (
        <>
            <Navigation />
            <div>

                <div className="mt-5 text-center">
                    <h1>Contrôle Qualité Locaux</h1>

                    <Container className='mt-5'>
                        <Form className='m-5' onSubmit={handleSubmit} >

                            <p>* Date: </p>
                            <DatePicker

                                dateFormat='dd/MM/yyyy'
                                value={Cdate}
                                onChange={(date) => {
                                    const dateString = new Date(date).toLocaleDateString("fr-FR")
                                    setDate(dateString)
                                }}
                                locale='fr'
                                showYearDropdown
                                scrollableMonthYearDropdown
                                className='mb-5 input'
                            />
                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Nom de l'inspecteur</InputGroup.Text>
                                <Form.Control type="Texte" value={inspector} onChange={(event) => { setInspector(event.target.value) }} />
                            </InputGroup>



                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Client / Chantier</InputGroup.Text>
                                <Form.Select value={client} onChange={(event) => { setClient(event.target.value) }} aria-label="Default select example">
                                    <option>Choisir un client</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={[client.client]}>{[client.client]}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>

                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>Localisation du contrôle</InputGroup.Text>
                                <Form.Control type="Texte" value={localisation} onChange={(event) => { setLocalisation(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>Présents</InputGroup.Text>
                                <Form.Control type="Texte" value={present} onChange={(event) => { setPresent(event.target.value) }} />
                            </InputGroup>

                            <hr />


                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            Hall d'accueil/entrée
                                            <input type="radio" style={{ marginLeft: '320px', marginRight: '5px' }} onChange={handleNaHall}/>
                                            <label >N/A</label>
                                        </Typography>

                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleHall) => (
                                                <div key={`inline-${poubelleHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoubelleHall(5)}
                                                        

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={1}
                                                        onChange={handlePoubelleHall}
               
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleHall}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleHall}

                                                        
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleHall}

                                                        
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleHall}
                                                    />
                                                   <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Cendriers</FormLabel>
                                            {['radio'].map((cendrierHall) => (
                                                <div key={`inline-${cendrierHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaCendrierHall(5)}
                                                        

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleCendrierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCendrierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCendrierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCendrierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCendrierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes Vitrées</FormLabel>
                                            {['radio'].map((portVitrHall) => (
                                                <div key={`inline-${portVitrHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="portVitrHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPortVitrHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="portVitrHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePortVitrHall}
                                                        disabled={naHallTotal === 60 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="portVitrHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePortVitrHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="portVitrHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePortVitrHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="portVitrHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePortVitrHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="portVitrHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePortVitrHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>

                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Mobilier</FormLabel>
                                            {['radio'].map((mobilierHall) => (
                                                <div key={`inline-${mobilierHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="mobilierHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaMobilierHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="mobilierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleMobilierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="mobilierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleMobilierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="mobilierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleMobilierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="mobilierHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMobilierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="mobilierHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMobilierHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Appuis Fenêtres</FormLabel>
                                            {['radio'].map((appuiHall) => (
                                                <div key={`inline-${appuiHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="appuiHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaAppuiHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleAppuiHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleAppuiHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleAppuiHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleAppuiHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleAppuiHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurHall) => (
                                                <div key={`inline-${radiateurHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRadiateurHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="radiateurHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleRadiateurHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Armoires</FormLabel>
                                            {['radio'].map((armoireHall) => (
                                                <div key={`inline-${armoireHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="armoireHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaArmoireHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="armoireHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleArmoireHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="armoireHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleArmoireHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="armoireHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleArmoireHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="armoireHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleArmoireHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="armoireHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleArmoireHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseHall) => (
                                                <div key={`inline-${priseHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPriseHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="priseHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePriseHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteHall) => (
                                                <div key={`inline-${porteHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="porteHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePorteHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Hautes Poussières</FormLabel>
                                            {['radio'].map((poussiereHall) => (
                                                <div key={`inline-${poussiereHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poussiereHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoussiereHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="poussiereHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePoussiereHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poussiereHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoussiereHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poussiereHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoussiereHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poussiereHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoussiereHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poussiereHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoussiereHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solHall) => (
                                                <div key={`inline-${solHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="solHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleSolHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheHall) => (
                                                <div key={`inline-${plintheHall}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheHall"
                                                        type="radio"
                                                        disabled={naHallTotal === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPlintheHall(5)}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="plintheHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePlintheHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheHall}
                                                        disabled={naHallTotal === 60 ? true : false}

                                                    />
                                                    <p className='text-center'>{naHallTotal === 60 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Hall / Entrée</InputGroup.Text>
                                            <Form.Control type="Texte" value={naHallTotal !== 60 ? `${totalHall} / ${hallTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>

                                        
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            Réception
                                            <input type="radio" style={{ marginLeft: '392px', marginRight: '5px' }} onChange={handleNaReception}/>
                                            <label >N/A</label>
                                        </Typography>

                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleReception) => (
                                                <div key={`inline-${poubelleReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                        value={"N/A"}
                                                        onChange={() => setNaPoubelleRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bureaux</FormLabel>
                                            {['radio'].map((bureauReception) => (
                                                <div key={`inline-${bureauReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bureauReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBureauRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bureauReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBureauRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bureauReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBureauRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bureauReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBureauRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bureauReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBureauRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bureauReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBureauRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Téléphones</FormLabel>
                                            {['radio'].map((phoneReception) => (
                                                <div key={`inline-${phoneReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="phoneReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaTelRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="phoneReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleTelRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="phoneReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleTelRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="phoneReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleTelRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="phoneReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleTelRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="phoneReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleTelRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Objets de bureau</FormLabel>
                                            {['radio'].map((objetReception) => (
                                                <div key={`inline-${objetReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="objetReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaObjRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="objetReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleObjRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="objetReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleObjRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="objetReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleObjRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="objetReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleObjRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="objetReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleObjRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sièges de bureau</FormLabel>
                                            {['radio'].map((siegeReception) => (
                                                <div key={`inline-${siegeReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="siegeReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSiegeRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="siegeReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSiegeRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="siegeReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSiegeRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="siegeReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSiegeRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="siegeReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSiegeRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="siegeReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSiegeRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Appuis Fenêtres</FormLabel>
                                            {['radio'].map((appuiReception) => (
                                                <div key={`inline-${appuiReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="appuiReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaAppuiRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="appuiReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleAppuiRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="appuiReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleAppuiRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="appuiReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleAppuiRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="appuiReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleAppuiRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="appuiReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleAppuiRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurReception) => (
                                                <div key={`inline-${radiateurReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaRadiateurRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Armoires</FormLabel>
                                            {['radio'].map((armoireReception) => (
                                                <div key={`inline-${armoireReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="armoireReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaArmoireRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="armoireReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleArmoireRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="armoireReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleArmoireRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="armoireReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleArmoireRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="armoireReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleArmoireRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="armoireReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleArmoireRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseReception) => (
                                                <div key={`inline-${priseReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPriseRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="priseReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePriseRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteReception) => (
                                                <div key={`inline-${porteReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPorteRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Hautes Poussières</FormLabel>
                                            {['radio'].map((poussiereReception) => (
                                                <div key={`inline-${poussiereReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poussiereReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPoussiereRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poussiereReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoussiereRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poussiereReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoussiereRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poussiereReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoussiereRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poussiereReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoussiereRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poussiereReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoussiereRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solReception) => (
                                                <div key={`inline-${solReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>


                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheReception) => (
                                                <div key={`inline-${plintheReception}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheReception"
                                                        type="radio"
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPlintheRecept(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheReception"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheRecept}
                                                        disabled={naReceptTotal === 65 ? true : false}
                                                    />
                                                    <p>{naReceptTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Réception</InputGroup.Text>
                                            <Form.Control type="Texte" value={naReceptTotal !== 65 ? `${totalReception} / ${receptionTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <Typography>
                                            Bureaux
                                            <input type="radio" style={{ marginLeft: '404px', marginRight: '5px' }} onChange={handleNaBureau}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleBureau) => (
                                                <div key={`inline-${poubelleBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPoubelleBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? 'NON APPLICABLE' : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bureaux</FormLabel>
                                            {['radio'].map((bureauBureau) => (
                                                <div key={`inline-${bureauBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBureauBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bureauBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBureauBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bureauBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBureauBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bureauBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBureauBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bureauBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBureauBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bureauBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBureauBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Téléphones</FormLabel>
                                            {['radio'].map((phoneBureau) => (
                                                <div key={`inline-${phoneBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="phoneBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPhoneBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="phoneBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePhoneBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="phoneBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePhoneBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="phoneBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePhoneBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="phoneBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePhoneBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="phoneBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePhoneBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Objets de bureau</FormLabel>
                                            {['radio'].map((objetBureau) => (
                                                <div key={`inline-${objetBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="objetBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaObjBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="objetBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleObjBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="objetBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleObjBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="objetBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleObjBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="objetBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleObjBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="objetBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleObjBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sièges de bureau</FormLabel>
                                            {['radio'].map((siegeBureau) => (
                                                <div key={`inline-${siegeBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="siegeBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSiegeBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="siegeBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSiegeBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="siegeBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSiegeBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="siegeBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSiegeBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="siegeBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSiegeBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="siegeBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSiegeBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Appuis Fenêtres</FormLabel>
                                            {['radio'].map((appuiBureau) => (
                                                <div key={`inline-${appuiBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="appuiBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaAppuiBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="appuiBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleAppuiBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="appuiBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleAppuiBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="appuiBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleAppuiBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="appuiBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleAppuiBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="appuiBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleAppuiBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurBureau) => (
                                                <div key={`inline-${radiateurBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaRadiateurBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Armoires</FormLabel>
                                            {['radio'].map((armoireBureau) => (
                                                <div key={`inline-${armoireBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="armoireBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={(e) => setNaArmoireBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="armoireBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleArmoireBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="armoireBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleArmoireBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="armoireBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleArmoireBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="armoireBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleArmoireBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="armoireBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleArmoireBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseBureau) => (
                                                <div key={`inline-${priseBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPriseBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="priseBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePriseBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteBureau) => (
                                                <div key={`inline-${porteBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPorteBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Hautes Poussières</FormLabel>
                                            {['radio'].map((poussiereBureau) => (
                                                <div key={`inline-${poussiereBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poussiereBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPoussiereBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poussiereBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoussiereBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poussiereBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoussiereBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poussiereBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoussiereBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poussiereBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoussiereBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poussiereBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoussiereBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solBureau) => (
                                                <div key={`inline-${solBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheBureau) => (
                                                <div key={`inline-${plintheBureau}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheBureau"
                                                        type="radio"
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPlintheBureau(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheBureau"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheBureau"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheBureau"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheBureau}
                                                        disabled={naBureauTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naBureauTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Bureaux</InputGroup.Text>
                                            <Form.Control type="Texte" value={naBureauTotal !== 65 ? `${totalBureaux} / ${bureauxTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <Typography >
                                            Sanitaires
                                            <input type="radio" style={{ marginLeft: '392px', marginRight: '5px' }} onChange={handleNaSanit}/>
                                            <label >N/A</label>
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Lavabos</FormLabel>
                                            {['radio'].map((lavaboSanit) => (
                                                <div key={`inline-${lavaboSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="lavaboSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaLavabSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="lavaboSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLavabSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="lavaboSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLavabSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="lavaboSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLavabSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="lavaboSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLavabSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="lavaboSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLavabSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Robinets / siphons</FormLabel>
                                            {['radio'].map((robinetSanit) => (
                                                <div key={`inline-${robinetSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="robinetSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaRobinSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="robinetSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRobinSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="robinetSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRobinSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="robinetSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRobinSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="robinetSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRobinSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="robinetSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRobinSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Miroirs</FormLabel>
                                            {['radio'].map((miroirSanit) => (
                                                <div key={`inline-${miroirSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="miroirSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaMiroirSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="miroirSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleMiroirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="miroirSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleMiroirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="miroirSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleMiroirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="miroirSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleMiroirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="miroirSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMiroirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Distributeurs de savon / papier</FormLabel>
                                            {['radio'].map((distributeurSanit) => (
                                                <div key={`inline-${distributeurSanit}`} className="mb-3">
                                                    
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="distributeurSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={(e) => setNaDistrSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="distributeurSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDistrSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="distributeurSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDistrSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="distributeurSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDistrSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="distributeurSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDistrSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="distributeurSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDistrSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleSanit) => (
                                                <div key={`inline-${poubelleSanit}`} className="mb-3">
                                                     <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPoubelleSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>WC</FormLabel>
                                            {['radio'].map((wcSanit) => (
                                                <div key={`inline-${wcSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="wcSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaWcSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="wcSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleWcSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="wcSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleWcSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="wcSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleWcSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="wcSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleWcSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="wcSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleWcSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Lunette WC</FormLabel>
                                            {['radio'].map((lunetteSanit) => (
                                                <div key={`inline-${lunetteSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="lunetteSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaLunSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="lunetteSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLunSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="lunetteSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLunSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="lunetteSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLunSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="lunetteSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLunSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="lunetteSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLunSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Urinoirs</FormLabel>
                                            {['radio'].map((urinoirSanit) => (
                                                <div key={`inline-${urinoirSanit}`} className="mb-3">
                                                     <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="urinoirSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaUrinoirSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="urinoirSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleUrinoirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="urinoirSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleUrinoirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="urinoirSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleUrinoirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="urinoirSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleUrinoirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="urinoirSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleUrinoirSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Douches</FormLabel>
                                            {['radio'].map((doucheSanit) => (
                                                <div key={`inline-${doucheSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="doucheSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={(e) => setNaDoucheSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="doucheSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDoucheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="doucheSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDoucheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="doucheSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDoucheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="doucheSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDoucheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="doucheSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDoucheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes / cloisons</FormLabel>
                                            {['radio'].map((cloisonSanit) => (
                                                <div key={`inline-${cloisonSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cloisonSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaCloisonSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="cloisonSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleCloisonSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cloisonSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCloisonSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cloisonSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCloisonSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cloisonSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCloisonSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cloisonSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCloisonSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurSanit) => (
                                                <div key={`inline-${radiateurSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaRaditeurSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solSanit) => (
                                                <div key={`inline-${solSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheSanit) => (
                                                <div key={`inline-${plintheSanit}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheSanit"
                                                        type="radio"
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPlintheSanit(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheSanit"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheSanit"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheSanit"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheSanit}
                                                        disabled={naSanitTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naSanitTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Sanitaires</InputGroup.Text>
                                            <Form.Control type="Texte" value={naSanitTotal !== 65 ? `${totalSanitaires} / ${sanitairesTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                    >

                                        <Typography >
                                            Salles de Réunion
                                            <input type="radio" style={{ marginLeft: '334px', marginRight: '5px' }} onChange={handleNaReunion}/>
                                            <label>N/A</label>
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleReunion) => (
                                                <div key={`inline-${poubelleReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPoubelleReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bureaux</FormLabel>
                                            {['radio'].map((bureauReunion) => (
                                                <div key={`inline-${bureauReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bureauReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBureauReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bureauReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBureauReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bureauReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBureauReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bureauReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBureauReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bureauReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBureauReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bureauReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBureauReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Téléphones</FormLabel>
                                            {['radio'].map((phoneReunion) => (
                                                <div key={`inline-${phoneReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="phoneReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPhoneReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="phoneReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePhoneReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="phoneReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePhoneReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="phoneReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePhoneReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="phoneReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePhoneReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="phoneReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePhoneReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                     <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p> 

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Objets de bureau</FormLabel>
                                            {['radio'].map((objetReunion) => (
                                                <div key={`inline-${objetReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="objetReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaObjReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="objetReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleObjReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="objetReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleObjReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="objetReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleObjReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="objetReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleObjReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="objetReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleObjReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sièges de bureau</FormLabel>
                                            {['radio'].map((siegeReunion) => (
                                                <div key={`inline-${siegeReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="siegeReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSiegeReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="siegeReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSiegeReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="siegeReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSiegeReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="siegeReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSiegeReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="siegeReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSiegeReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="siegeReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSiegeReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Appuis Fenêtres</FormLabel>
                                            {['radio'].map((appuiReunion) => (
                                                <div key={`inline-${appuiReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="appuiReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaAppuiReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="appuiReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleAppuiReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="appuiReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleAppuiReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="appuiReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleAppuiReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="appuiReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleAppuiReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="appuiReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleAppuiReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurReunion) => (
                                                <div key={`inline-${radiateurReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaRadiateurReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Armoires</FormLabel>
                                            {['radio'].map((armoireReunion) => (
                                                <div key={`inline-${armoireReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="armoireReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaArmoireReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="armoireReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleArmoirReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="armoireReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleArmoirReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="armoireReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleArmoirReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="armoireReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleArmoirReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="armoireReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleArmoirReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseReunion) => (
                                                <div key={`inline-${priseReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPriseReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="priseReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePriseReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteReunion) => (
                                                <div key={`inline-${porteReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPorteReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteReunionn"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Hautes Poussières</FormLabel>
                                            {['radio'].map((poussiereReunion) => (
                                                <div key={`inline-${poussiereReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poussiereReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPoussiereReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poussiereReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoussiereReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poussiereReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoussiereReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poussiereReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoussiereReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poussiereReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoussiereReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poussiereReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoussiereReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solReunion) => (
                                                <div key={`inline-${solReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheReunion) => (
                                                <div key={`inline-${plintheReunion}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheReunion"
                                                        type="radio"
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPlintheReunion(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheReunion"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheReunion"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheReunion"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheReunion}
                                                        disabled={naReunionTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naReunionTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Salles de Réunion</InputGroup.Text>
                                            <Form.Control type="Texte" value={naReunionTotal !== 65 ? `${totalReunion} / ${reunionTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6a-content"
                                        id="panel6a-header"
                                    >
                                        <Typography>
                                            Douches/Vestiares
                                            <input type="radio" style={{ marginLeft: '331px', marginRight: '5px' }} onChange={handleNaDouche}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Lavabos</FormLabel>
                                            {['radio'].map((lavaboDouche) => (
                                                <div key={`inline-${lavaboDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="lavaboDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaLavabDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="lavaboDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLavabDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="lavaboDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLavabDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="lavaboDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLavabDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="lavaboDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLavabDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="lavaboDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLavabDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Robinets / siphons</FormLabel>
                                            {['radio'].map((robinetDouche) => (
                                                <div key={`inline-${robinetDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="robinetDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaRobinDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="robinetDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRobinDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="robinetDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRobinDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="robinetDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRobinDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="robinetDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRobinDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="robinetDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRobinDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Miroirs</FormLabel>
                                            {['radio'].map((miroirDouche) => (
                                                <div key={`inline-${miroirDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="miroirDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaMiroirDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="miroirDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleMiroirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="miroirDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleMiroirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="miroirDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleMiroirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="miroirDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleMiroirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="miroirDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMiroirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Distributeurs de savon / papier</FormLabel>
                                            {['radio'].map((distributeurDouche) => (
                                                <div key={`inline-${distributeurDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="distributeurDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaDistrDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="distributeurDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDistrDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="distributeurDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDistrDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="distributeurDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDistrDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="distributeurDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDistrDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="distributeurDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDistrDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleDouche) => (
                                                <div key={`inline-${poubelleDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPoubelleDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>WC</FormLabel>
                                            {['radio'].map((wcDouche) => (
                                                <div key={`inline-${wcDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="wcDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaWcDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="wcDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleWcDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="wcDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleWcDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="wcDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleWcDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="wcDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleWcDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="wcDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleWcDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Lunette WC</FormLabel>
                                            {['radio'].map((lunetteDouche) => (
                                                <div key={`inline-${lunetteDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="lunetteDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaLunDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="lunetteDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLunDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="lunetteDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLunDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="lunetteDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLunDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="lunetteDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLunDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="lunetteDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLunDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Urinoirs</FormLabel>
                                            {['radio'].map((urinoirDouche) => (
                                                <div key={`inline-${urinoirDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="urinoirDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaUrinoirDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="urinoirDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleUrinoirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="urinoirDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleUrinoirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="urinoirDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleUrinoirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="urinoirDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleUrinoirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="urinoirDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleUrinoirDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Douches</FormLabel>
                                            {['radio'].map((doucheDouche) => (
                                                <div key={`inline-${doucheDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="doucheDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaDoucheDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="doucheDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDoucheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="doucheDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDoucheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="doucheDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDoucheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="doucheDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDoucheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="doucheDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDoucheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes / cloisons</FormLabel>
                                            {['radio'].map((cloisonDouche) => (
                                                <div key={`inline-${cloisonDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cloisonDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaCloisonDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="cloisonDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleCloisonDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cloisonDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCloisonDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cloisonDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCloisonDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cloisonDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCloisonDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cloisonDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCloisonDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurDouche) => (
                                                <div key={`inline-${radiateurDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaRadiateurDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solDouche) => (
                                                <div key={`inline-${solDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheDouche) => (
                                                <div key={`inline-${plintheDouche}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheDouche"
                                                        type="radio"
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPlintheDouche(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheDouche"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheDouche"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheDouche"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheDouche}
                                                        disabled={naDoucheTotal === 65 ? true : false}
                                                    />
                                                    <p className="text-center">{naDoucheTotal === 65 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Douches/Vestiaires</InputGroup.Text>
                                            <Form.Control type="Texte" value={naDoucheTotal !== 65 ? `${totalDouche} / ${doucheTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel7a-content"
                                        id="panel7a-header"
                                    >
                                        <Typography>
                                            Réfectoire
                                            <input type="radio" style={{ marginLeft: '391px', marginRight: '5px' }} onChange={handleNaRefect}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solRefect) => (
                                                <div key={`inline-${solRefect}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solRefect"
                                                        type="radio"
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolRefect(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solRefect"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naRefectTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / coins</FormLabel>
                                            {['radio'].map((bordRefect) => (
                                                <div key={`inline-${bordRefect}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordRefect"
                                                        type="radio"
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBordRefect(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordRefect"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naRefectTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteRefect) => (
                                                <div key={`inline-${porteRefect}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteRefect"
                                                        type="radio"
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPorteRefect(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteRefect"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naRefectTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Tables</FormLabel>
                                            {['radio'].map((tableRefect) => (
                                                <div key={`inline-${tableRefect}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="tableRefect"
                                                        type="radio"
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaTableRefect(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="tableRefect"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleTableRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="tableRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleTableRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="tableRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleTableRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="tableRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleTableRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="tableRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleTableRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naRefectTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Chaises</FormLabel>
                                            {['radio'].map((chaiseRefect) => (
                                                <div key={`inline-${chaiseRefect}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="chaiseRefect"
                                                        type="radio"
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaChaiseRefect(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="chaiseRefect"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleChaiseRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="chaiseRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleChaiseRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="chaiseRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleChaiseRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="chaiseRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleChaiseRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="chaiseRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleChaiseRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naRefectTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Dépoussiérage en général</FormLabel>
                                            {['radio'].map((depoussierageRefect) => (
                                                <div key={`inline-${depoussierageRefect}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="depoussierageRefect"
                                                        type="radio"
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaDepoussRefect(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="depoussierageRefect"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDepoussRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="depoussierageRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDepoussRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="depoussierageRefect"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDepoussRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="depoussierageRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDepoussRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="depoussierageRefect"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDepoussRefect}
                                                        disabled={naRefectTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naRefectTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Réfectoire</InputGroup.Text>
                                            <Form.Control type="Texte" value={naRefectTotal !== 30 ? `${totalRefect} / ${refectTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8a-content"
                                        id="panel8a-header"
                                    >
                                        <Typography>
                                            Cuisine
                                            <input type="radio" style={{ marginLeft: '411px', marginRight: '5px' }} onChange={handleNaCuisine}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solCuisine) => (
                                                <div key={`inline-${solCuisine}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solCuisine"
                                                        type="radio"
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolCuisine(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solCuisine"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCuisineTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / Coins</FormLabel>
                                            {['radio'].map((bordCuisine) => (
                                                <div key={`inline-${bordCuisine}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordCuisine"
                                                        type="radio"
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBordCuisine(5)}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordCuisine"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCuisineTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteCuisine) => (
                                                <div key={`inline-${porteCuisine}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteCuisine"
                                                        type="radio"
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={(e) => setNaPorteCuisine(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteCuisine"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCuisineTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Tables</FormLabel>
                                            {['radio'].map((tableCuisine) => (
                                                <div key={`inline-${tableCuisine}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="tableCuisine"
                                                        type="radio"
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaTableCuisine(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="tableCuisine"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleTableCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="tableCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleTableCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="tableCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleTableCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="tableCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleTableCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="tableCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleTableCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCuisineTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Chaises</FormLabel>
                                            {['radio'].map((chaiseCuisine) => (
                                                <div key={`inline-${chaiseCuisine}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="chaiseCuisine"
                                                        type="radio"
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaChaiseCuisine(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="chaiseCuisine"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleChaiseCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="chaiseCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleChaiseCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="chaiseCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleChaiseCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="chaiseCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleChaiseCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="chaiseCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleChaiseCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCuisineTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Dépoussiérage en général</FormLabel>
                                            {['radio'].map((depoussierageCuisine) => (
                                                <div key={`inline-${depoussierageCuisine}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="depoussierageCuisine"
                                                        type="radio"
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaDepoussCuisine(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="depoussierageCuisine"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDepoussCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="depoussierageCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDepoussCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="depoussierageCuisine"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDepoussCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="depoussierageCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDepoussCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="depoussierageCuisine"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDepoussCuisine}
                                                        disabled={naCuisineTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCuisineTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Cuisine</InputGroup.Text>
                                            <Form.Control type="Texte" value={naCuisineTotal !== 30 ? `${totalCuisine} / ${cuisineTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel9a-content"
                                        id="panel9a-header"
                                    >
                                        <Typography>
                                            Circulations
                                            <input type="radio" style={{ marginLeft: '380px', marginRight: '5px' }} onChange={handleNaCircul}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solCirculation) => (
                                                <div key={`inline-${solCirculation}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solCirculation"
                                                        type="radio"
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolCircul(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solCirculation"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCirculTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / Coins</FormLabel>
                                            {['radio'].map((bordCirculation) => (
                                                <div key={`inline-${bordCirculation}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordCirculation"
                                                        type="radio"
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBordCircul(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordCirculation"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCirculTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteCirculation) => (
                                                <div key={`inline-${porteCirculation}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteCirculation"
                                                        type="radio"
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPorteCircul(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteCirculation"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCirculTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Encadrements</FormLabel>
                                            {['radio'].map((cadreCirculation) => (
                                                <div key={`inline-${cadreCirculation}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cadreCirculation"
                                                        type="radio"
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaCadreCircul(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="cadreCirculation"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleCadreCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cadreCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCadreCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cadreCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCadreCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cadreCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCadreCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cadreCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCadreCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCirculTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseCirculation) => (
                                                <div key={`inline-${priseCirculation}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseCirculation"
                                                        type="radio"
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPriseCircul(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="priseCirculation"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePriseCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCirculTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheCirculation) => (
                                                <div key={`inline-${plintheCirculation}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheCirculation"
                                                        type="radio"
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPlintheCircul(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheCirculation"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheCirculation"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheCirculation"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheCircul}
                                                        disabled={naCirculTotal === 30 ? true : false}
                                                    />
                                                    <p className="text-center">{naCirculTotal === 30 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Circulations</InputGroup.Text>
                                            <Form.Control type="Texte" value={naCirculTotal !== 30 ? `${totalCirculation} / ${circulationsTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        <Typography>
                                            Ascenseur
                                            <input type="radio" style={{ marginLeft: '389px', marginRight: '5px' }} onChange={handleNaAscenseur}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solAscenseur) => (
                                                <div key={`inline-${solAscenseur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solAscenseur"
                                                        type="radio"
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolAscens(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solAscenseur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <p className="text-center">{naAscenseurTotal === 35 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / Coins</FormLabel>
                                            {['radio'].map((bordAscenseur) => (
                                                <div key={`inline-${bordAscenseur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordAscenseur"
                                                        type="radio"
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBordAscens(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordAscenseur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <p className="text-center">{naAscenseurTotal === 35 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteAscenseur) => (
                                                <div key={`inline-${porteAscenseur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteAscenseur"
                                                        type="radio"
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPorteAscens(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteAscenseur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <p className="text-center">{naAscenseurTotal === 35 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Miroirs</FormLabel>
                                            {['radio'].map((miroirAscenseur) => (
                                                <div key={`inline-${miroirAscenseur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="miroirAscenseur"
                                                        type="radio"
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaMiroirAscens(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="miroirAscenseur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleMiroirAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="miroirAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleMiroirAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="miroirAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleMiroirAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="miroirAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleMiroirAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="miroirAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMiroirAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <p className="text-center">{naAscenseurTotal === 35 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Paroi intérieure</FormLabel>
                                            {['radio'].map((parIntAscenseur) => (
                                                <div key={`inline-${parIntAscenseur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="parIntAscenseur"
                                                        type="radio"
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaParoiIntAscens(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="parIntAscenseur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleParoiIntAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="parIntAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleParoiIntAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="parIntAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleParoiIntAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="parIntAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleParoiIntAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="parIntAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleParoiIntAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <p className="text-center">{naAscenseurTotal === 35 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Paroi extérieure</FormLabel>
                                            {['radio'].map((parExtAscenseur) => (
                                                <div key={`inline-${parExtAscenseur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="parExtAscenseur"
                                                        type="radio"
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaParoiExtAscens(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="parExtAscenseur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleParoiExtAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="parExtAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleParoiExtAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="parExtAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleParoiExtAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="parExtAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleParoiExtAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="parExtAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleParoiExtAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <p className="text-center">{naAscenseurTotal === 35 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Dépoussiérage en général</FormLabel>
                                            {['radio'].map((depoussierageAscenseur) => (
                                                <div key={`inline-${depoussierageAscenseur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="depoussierageAscenseur"
                                                        type="radio"
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaDepoussAscens(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="depoussierageAscenseur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDepoussAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="depoussierageAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDepoussAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="depoussierageAscenseur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDepoussAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="depoussierageAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDepoussAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="depoussierageAscenseur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDepoussAscenseur}
                                                        disabled={naAscenseurTotal === 35 ? true : false}
                                                    />
                                                    <p className="text-center">{naAscenseurTotal === 35 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Ascenseur</InputGroup.Text>
                                            <Form.Control type="Texte" value={naAscenseurTotal !== 35 ? `${totalAscenseur} / ${ascenseurTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        <Typography>
                                            Escaliers
                                            <input type="radio" style={{ marginLeft: '399px', marginRight: '5px' }} onChange={handleNaEscaliers}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solEscalier) => (
                                                <div key={`inline-${solEscalier}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solEscalier"
                                                        type="radio"
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaSolEscal(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solEscalier"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <p className="text-center">{naEscalTotal === 25 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / Coins</FormLabel>
                                            {['radio'].map((bordEscalier) => (
                                                <div key={`inline-${bordEscalier}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordEscalier"
                                                        type="radio"
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBordEscal(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordEscalier"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <p className="text-center">{naEscalTotal === 25 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteEscalier) => (
                                                <div key={`inline-${porteEscalier}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteEscalier"
                                                        type="radio"
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPorteEscal(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteEscalier"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <p className="text-center">{naEscalTotal === 25 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Encadrements</FormLabel>
                                            {['radio'].map((cadreEscalier) => (
                                                <div key={`inline-${cadreEscalier}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cadreEscalier"
                                                        type="radio"
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaCadreEscal(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="cadreEscalier"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleCadreEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cadreEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCadreEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cadreEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCadreEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cadreEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCadreEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cadreEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCadreEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <p className="text-center">{naEscalTotal === 25 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Main courante</FormLabel>
                                            {['radio'].map((rampeEscalier) => (
                                                <div key={`inline-${rampeEscalier}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="rampeEscalier"
                                                        type="radio"
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaRampeEscal(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="rampeEscalier"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRampeEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="rampeEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRampeEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="rampeEscalier"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRampeEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="rampeEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRampeEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="rampeEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRampeEscal}
                                                        disabled={naEscalTotal === 25 ? true : false}
                                                    />
                                                    <p className="text-center">{naEscalTotal === 25 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Escaliers</InputGroup.Text>
                                            <Form.Control type="Texte" value={naEscalTotal !== 25 ? `${totalEscalier} / ${escaliersTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        <Typography>
                                            Parking
                                            <input type="radio" style={{ marginLeft: '410px', marginRight: '5px' }} onChange={handleNaParking}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Visuel gros papiers</FormLabel>
                                            {['radio'].map((visuelParking) => (
                                                <div key={`inline-${visuelParking}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="visuelParking"
                                                        type="radio"
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaVisuelParking(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="visuelParking"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleVisuelParking}
                                                        disabled={naParkingTotal === 10 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="visuelParking"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleVisuelParking}
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="visuelParking"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleVisuelParking}
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="visuelParking"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleVisuelParking}
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="visuelParking"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleVisuelParking}
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                    />
                                                    <p className="text-center">{naParkingTotal === 10 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleParking) => (
                                                <div key={`inline-${poubelleParking}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleParking"
                                                        type="radio"
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaPoubelleParking(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleParking"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleParking}
                                                        disabled={naParkingTotal === 10 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleParking"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleParking}
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleParking"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleParking}
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleParking"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleParking}
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleParking"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleParking}
                                                        disabled={naParkingTotal === 10 ? true : false}
                                                    />
                                                    <p className="text-center">{naParkingTotal === 10 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Parking</InputGroup.Text>
                                            <Form.Control type="Texte" value={naParkingTotal !== 10 ? `${totalParking} / ${parkingTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel11a-content"
                                        id="panel11a-header"
                                    >
                                        <Typography>
                                            Local de Nettoyage / Chariots / Produits
                                            <input type="radio" style={{ marginLeft: '181px', marginRight: '5px' }} onChange={handleNaLocal}/>
                                            <label>N/A</label>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Local de nettoyage</FormLabel>
                                            {['radio'].map((local) => (
                                                <div key={`inline-${local}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="local"
                                                        type="radio"
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaLocalLocal(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="local"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLocal}
                                                        disabled={naLocalTotal === 15 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="local"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLocal}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="local"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLocal}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="local"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLocal}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="local"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLocal}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <p className="text-center">{naLocalTotal === 15 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Propreté chariots / matériel</FormLabel>
                                            {['radio'].map((chariot) => (
                                                <div key={`inline-${chariot}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="chariot"
                                                        type="radio"
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaChariotLocal(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="chariot"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleChariot}
                                                        disabled={naLocalTotal === 15 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="chariot"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleChariot}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="chariot"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleChariot}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="chariot"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleChariot}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="chariot"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleChariot}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <p className="text-center">{naLocalTotal === 15 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Stock Produits</FormLabel>
                                            {['radio'].map((stock) => (
                                                <div key={`inline-${stock}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="stock"
                                                        type="radio"
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaStockLocal(5)}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="stock"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleStock}
                                                        disabled={naLocalTotal === 15 ? true : false}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="stock"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleStock}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="stock"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleStock}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="stock"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleStock}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="stock"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleStock}
                                                        disabled={naLocalTotal === 15 ? true : false}
                                                    />
                                                    <p className="text-center">{naLocalTotal === 15 ? "NON APPLICABLE" : ""}</p>

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Local de Nettoyage / Chariots / Produits</InputGroup.Text>
                                            <Form.Control type="Texte" value={naLocalTotal !== 15 ?`${totalLocal} / ${localTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                            </div>



                            <InputGroup className='mb-5 p-3 mt-5'>
                                <InputGroup.Text>Constatations sur le nettoyage</InputGroup.Text>
                                <FormControl as="textarea" value={remarks} onChange={(event) => { setRemarks(event.target.value) }} aria-label="With textarea" />
                            </InputGroup>

                            <InputGroup className='mb-5 p-3' >
                                <InputGroup.Text>Actions à prendre</InputGroup.Text>
                                <FormControl as="textarea" name="actions" value={action} onChange={(event) => { setAction(event.target.value) }} aria-label="With textarea" />
                            </InputGroup>



                            <InputGroup className="m-auto mt-5 ">
                                <InputGroup.Text>Evaluation Générale</InputGroup.Text>
                                <Form.Control type="Texte" value={totalEval + perc} readOnly={true} />
                            </InputGroup>
                            {<h3 className={totalEval < 70 ? 'text-center text-danger mb-5 p-3' : 'text-center text-success mb-5 p-3'} >{totalEval < 70 ? "Insatisfaisant" : totalEval < 80 ? "Satisfaisant" : "Très bien"}</h3>}



                            <InputGroup className="mb-5 p-3">
                                <InputGroup.Text>Nom Client</InputGroup.Text>
                                <Form.Control type="Texte" value={clientName} onChange={(event) => { setClientName(event.target.value) }} />
                            </InputGroup>

                            <p className='text-center mb-2 h5'>Signature Client ( Signez et Validez )</p>
                            <InputGroup>

                                <SignaturePad
                                    ref={sigCanvas}
                                    canvasProps={{
                                        className: "signatureCanvas"
                                    }}
                                />
                            </InputGroup>
                            <button onClick={clear} className="mb-5 mt-1 me-3">Effacer</button>
                            <button onClick={save} className="mb-5 mt-1">Valider</button>


                            <div>
                                <Button variant="primary" className="mt-3" type="submit">
                                    Enregistrer
                                </Button>
                            </div>
                        </Form>
                    </Container>
                    <div >

                    </div>{message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => {
                        if (message?.error) {
                            setMessage('')
                        } else {
                            window.location.reload()
                        }


                    }


                    }>{message?.msg}</Alert>)}
                </div>
            </div>
        </>
    )
}

export default SuiviQualite