import './dataTableProducts.scss'
import { useState, useEffect } from 'react'
import ProductDataService from '../../services/productsServices'
import { DataGrid } from '@mui/x-data-grid'
import { productsColumns } from '../../dataTableProducts'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'


const DataTableProducts = ({ getProductId, open }) => {



    //Voir les produits
    const [products, setProducts] = useState([])

    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = async () => {
        const data = await ProductDataService.getAllProducts()
        setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }



    //Supprimer un produit
    const handleDelete =  (id) => {
        confirmAlert({
            title:'Confirmez',
            message: 'Etes-vous sûr de vouloir supprimer ce produit ?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: async () =>  {
                        await ProductDataService.deleteProduct(id)
                        getProducts()
                    }
                },
                {
                    label: 'Non',
                    
                }
            ]
        })
       
    }

    const actionColumn = [{
        field: "action",
        headerName: "Action",
        width: 150,
        renderCell: (params) => {
            return (
                <div className='cellAction'>

                    <div className="editButton" onClick={(e) => getProductId(params.row.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="MODIFIER"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffa500" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg></div>

                    <div className="deleteButton" onClick={() => handleDelete(params.row.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="SUPPRIMER"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ff0000" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                    </svg></div>
                </div>
            )
        }
    }]

    return (
        <>
            <div className='datatable'>
                <div className="datatableTitle" >

                    <button onClick={open} className="link"> Ajouter un article +</button>

                </div>
                <DataGrid
                    className='datagrid'
                    rows={products}
                    columns={productsColumns.concat(actionColumn)}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                />
            </div>

        </>
    )

}
export default DataTableProducts