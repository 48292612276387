import {
  BrowserRouter,
  Routes,
  Route
  
} from "react-router-dom"
import Login from "./pages/login/Login"
import Home from "./pages/home/Home"
import RapportPassage from "./pages/rapport_passage/RapportPassage"
import CheckMateriel from "./pages/check_materiel/CheckMateriel"
import SuiviQualite from "./pages/suivi-qualite/SuiviQualite"
import PrivateUsers from "./pages/privateUsers/PrivateUsers"
import ListClients from "./pages/listClients/ListClients"
import ListRapportsPassage from "./pages/listRapportsPassages/ListRapportsPassage"
import ListSuiviQualite from "./pages/listSuiviQualite/ListSuiviQualite"
import RapportPassageSingle from "./pages/rapports/rapportPassageSingle/RapportPassageSingle"
import SuiviQualiteSingle from "./pages/rapports/suiviQualiteSingle/SuiviQualiteSingle"
import NewClient from "./pages/newClient/NewClient"
import ListInactiveClients from "./pages/listInactiveClients/ListInactiveClients"
import ListProducts from "./pages/listProducts/ListProducts"
import NewProduct from "./pages/newProduct/NewProduct"
import ListCheckMateriels from "./pages/listCheckMateriel/ListCheckMateriels"
import CheckMaterielSingle from './pages/rapports/checkMaterielSingle/CheckMaterielSingle'
import AccesClients from "./pages/acces_clients/AccesClients"
import ProtectedRoute from "./components/protectedRoutes/ProtectedRoute"
import WorkerRoute from "./components/protectedRoutes/WorkerRoute"
import ClientRoute from "./components/protectedRoutes/ClientRoute"



function App() {

  

  return (

    <div className="App">
      <BrowserRouter>
        

        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
    
            
            <Route path="home" element={<ProtectedRoute><Home /></ProtectedRoute>} />

            
            <Route path="clients" element={<ProtectedRoute><ListClients /></ProtectedRoute>} />
            <Route path="inactive" element={<ProtectedRoute><ListInactiveClients /></ProtectedRoute>} />
            <Route path="newClient" element={<ProtectedRoute><NewClient /></ProtectedRoute>} />

            <Route path="products" element={<ProtectedRoute><ListProducts /></ProtectedRoute>} />
            <Route path="newProduct" element={<ProtectedRoute><NewProduct /></ProtectedRoute>} />

            
            <Route path="listRapportsPassage" element={<ProtectedRoute><ListRapportsPassage /></ProtectedRoute>} />
            <Route path="rapportPassageSingle" element={<ProtectedRoute><RapportPassageSingle /></ProtectedRoute>} />

            <Route path="listCheckMateriels" element={<ProtectedRoute><ListCheckMateriels /></ProtectedRoute>} />
            <Route path="checkMaterielSingle" element={<ProtectedRoute><CheckMaterielSingle /></ProtectedRoute>} />

            <Route path="listSuiviQualite" element={<ProtectedRoute> <ListSuiviQualite /></ProtectedRoute>} />
            <Route path="suiviQualiteSingle" element={<ProtectedRoute><SuiviQualiteSingle /></ProtectedRoute>} />

            
            <Route path="users" element={<ProtectedRoute><PrivateUsers /></ProtectedRoute>} />
           
           
            <Route path="rapport_passage" element={<WorkerRoute><RapportPassage /></WorkerRoute>} />
            <Route path="check_materiel" element={<WorkerRoute><CheckMateriel /></WorkerRoute>} />
            <Route path="suivi_qualite" element={<WorkerRoute><SuiviQualite /></WorkerRoute>} />
            
           
            
            <Route path="acces_clients" element={<ClientRoute><AccesClients /></ClientRoute>} /> 
            
            

          </Route>
        </Routes>
        
      </BrowserRouter>
    </div>
  );

}

export default App;