import { Navigate } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'



const WorkerRoute = ({ children }) => {

    const admin = localStorage.getItem("isAdmin")
    const worker = localStorage.getItem("isWorker")

    let {user} = useUserAuth()
    
    if (!user ) {
        return <Navigate to='/' />
    }

    else if( admin || worker ){
        return children
    }

}

export default WorkerRoute