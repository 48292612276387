
import './rapportPassageSingle.scss'
import { Form, Container, InputGroup, FormControl, Alert, Button, ToggleButton } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import RapportsPassagesDataService from '../../../services/rapportsPassagesServices'
import ClientDataService from '../../../services/clientsService'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import CopyText from '../../../components/CopyText'



function RapportPassageSingle({ id, setRapportId }) {



    //voir les clients
    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }

    //states
    const [date, setDate] = useState("")
    const [client, setClient] = useState("")
    const [present, setPresent] = useState("")
    const [clothes, setClothes] = useState("")
    const [presentation, setPresentation] = useState("")
    const [cart, setCart] = useState("")
    const [products, setProducts] = useState("")
    const [colors, setColors] = useState("")
    const [remarks, setRemarks] = useState("")
    const [actions, setActions] = useState("")
    const [clientName, setClientName] = useState("")
    const [images, setImages] = useState([])
    const [signature, setSignature] = useState('')
    const [checked, setChecked] = useState('')

    const [message, setMessage] = useState({ error: false, msg: "" })


    //update
    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessage("")

        const newRapport = {
            date: date,
            client: client,
            present: present,
            clothes: clothes,
            presentation: presentation,
            cart: cart,
            products: products,
            colors: colors,
            remarks: remarks,
            actions: actions,
            clientName: clientName,
            checked: checked === undefined ? '' : 'Vérifié'
        }

        try {
            if (id !== undefined && id !== "") {
                await RapportsPassagesDataService.updateRapportPassage(id, newRapport)
                console.log(checked)
                setRapportId("")
                setMessage({ error: false, msg: "Les modifications ont été enregistrées" })
            }
            else {
                setMessage({ error: true, msg: "Le rapport est vide" })
            }

        } catch (err) {
            setMessage({ error: true, msg: err.message })
        }

    }

    //voir les données
    const handleEdit = async () => {
        setMessage("")

        try {
            const docSnap = await RapportsPassagesDataService.getRapportPassage(id)
            setDate(docSnap.data().date)
            setClient(docSnap.data().client)
            setPresent(docSnap.data().present)
            setClothes(docSnap.data().clothes)
            setPresentation(docSnap.data().presentation)
            setCart(docSnap.data().cart)
            setProducts(docSnap.data().products)
            setColors(docSnap.data().colors)
            setRemarks(docSnap.data().remarks)
            setActions(docSnap.data().actions)
            setClientName(docSnap.data().clientName)
            setImages(docSnap.data().image)
            setSignature(docSnap.data().signature)
            setChecked(docSnap.data().checked)
        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }
    }

    useEffect(() => {
        if (id !== undefined && id !== "") {
            handleEdit()
        }
    }, [id])

    //imprimer le rapport, créer PDF et envoyer par mail
    const [sent, setSent] = useState(false)
    const [displayPrint, setDisplayPrint] = useState(false)

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `rapport de Passage ${date}${client} `,
        onAfterPrint: () => setSent(true)
    })

    const handlePDF = (e) => {
        e.preventDefault()

        getClientEmail()
        confirmAlert({
            title: 'Confirmez',
            message: "Etes-vous sûr d'avoir vérifié ce rapport avant de l'envoyer ?",
            buttons: [
                {
                    label: 'Oui',
                    onClick: async () => {

                        const rapport = {
                            date: date,
                            client: client,
                            present: present,
                            clothes: clothes,
                            presentation: presentation,
                            cart: cart,
                            products: products,
                            colors: colors,
                            remarks: remarks,
                            actions: actions,
                            clientName: clientName,
                            sent: sent === false ? 'OUI' : ''
                        }
                        try {
                            await RapportsPassagesDataService.updateRapportPassage(id, rapport)
                        }
                        catch (err) {
                            setMessage({ error: true, msg: err.message })
                        }
                        setDisplayPrint(true)
                    }
                },
                {
                    label: 'Non',

                }
            ]
        })
    }

    //Trouver l'email du client
    const [clientEmail, setClientEmail] = useState('')

    const getClientEmail = () => clients.map((thisClient) => {

        if (thisClient.client === client) {
            setClientEmail(thisClient.email);
        }
    })

    if (displayPrint === true) {
        return (
            <>
                <Button className='m-5 mb-3 bg-secondary' onClick={handlePrint} style={{ cursor: "pointer" }}>Print / PDF et envoyer</Button>
                <CopyText clientEmail={clientEmail} />
                <div ref={componentRef} className='text-center'>
                    <h4 className='mb-3 '>Rapport de Passage du {date}</h4>
                    <Container className='w-50 mb-5'>

                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Client</InputGroup.Text>
                            <Form.Control type="Texte" value={client} readOnly={true} />
                        </InputGroup>
                        <InputGroup className="p-3" >
                            <InputGroup.Text>Présents</InputGroup.Text>
                            <Form.Control type="Texte" value={present} readOnly={true} />
                        </InputGroup>
                    </Container>
                    <div className='text-center mt-2'>
                        <h4>Rencontre du personnel sur place</h4>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>évaluation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Vêtements de travail</td>
                                    <td>{clothes}</td>
                                </tr>
                                <tr>
                                    <td>Présentation du personnel exécutant</td>
                                    <td>{presentation}</td>
                                </tr>
                                <tr>
                                    <td>Présentation chariot</td>
                                    <td>{cart}</td>
                                </tr>
                                <tr>
                                    <td>Utilisation respective des produits et dosages</td>
                                    <td>{products}</td>
                                </tr>
                                <tr>
                                    <td>Respect des codes couleurs</td>
                                    <td>{colors}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <Container className='w-50 mt-5'>
                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Constatations sur le nettoyage</InputGroup.Text>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="pas de constatation particulière"
                                style={{ width: '100%' }}
                                className='mb-5 p-3'
                                value={remarks}
                                readOnly={true}
                            />
                        </InputGroup>

                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Actions à prendre</InputGroup.Text>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="pas d'action requise"
                                style={{ width: '100%' }}
                                className='mb-5 p-3'
                                value={actions}
                                readOnly={true}
                            />
                        </InputGroup>



                        <InputGroup className="mb-1 p-3">
                            <InputGroup.Text>Nom du Client</InputGroup.Text>
                            <Form.Control type="Texte" value={clientName} readOnly={true} />
                        </InputGroup>



                        <div className="mb-1 p-3 signature">
                            <p><img src={signature} alt="" />Signature client</p>
                        </div>
                        {images.map((image) => {

                            return (

                                <div key={image} className='mt-5'>
                                    <img src={image} alt="" style={{ width: '500px', margin: '10px' }} />
                                </div>
                            )


                        })}

                    </Container>
                </div>
            </>
        )
    }

    else {
        return (
            <div className="rapportPassageSingle">

                <div className="rapportPassageSingleContainer">


                    <div>
                        <div className="printSend">
                            <Button className='bg-warning text-dark print' onClick={handlePDF}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-1 bi bi-printer-fill" viewBox="0 0 16 16" >
                                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            </svg>Créer PDF et envoyer ou imprimer</Button>

                        </div>

                        <div className="mt-5 text-center" >
                            <h3 className='printTitle'>Rapport du {date}</h3>
                            <Container className='mt-5' >
                                <form onSubmit={handleSubmit} className='m-5'>

                                    <InputGroup className='mb-5 p-3'>
                                        <InputGroup.Text>Date</InputGroup.Text>
                                        <Form.Control type="Texte" value={date} onChange={(event) => { setDate(event.target.value) }} />
                                    </InputGroup>



                                    <InputGroup className='mb-5 p-3'>
                                        <InputGroup.Text>* Client</InputGroup.Text>
                                        <Form.Select value={client} onChange={(event) => { setClient(event.target.value) }} aria-label="Default select example">
                                            <option>Choisir un client</option>
                                            {clients.map((client) => (
                                                <option key={client.id} value={[client.client]}>{[client.client]}</option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>

                                    <InputGroup className="mb-5 p-3" >
                                        <InputGroup.Text>Présents</InputGroup.Text>
                                        <Form.Control type="Texte" value={present} onChange={(event) => { setPresent(event.target.value) }} />
                                    </InputGroup>


                                    <h3 className='mb-3'>Rencontre du personnel sur place</h3>

                                    <InputGroup className="mb-5 p-3" >
                                        <InputGroup.Text>Vêtements de travail</InputGroup.Text>
                                        <Form.Control type="Texte" value={clothes} onChange={(event) => { setClothes(event.target.value) }} />
                                    </InputGroup>

                                    <InputGroup className="mb-5 p-3" >
                                        <InputGroup.Text>Présentation du personnel exécutant </InputGroup.Text>
                                        <Form.Control type="Texte" value={presentation} onChange={(event) => { setPresentation(event.target.value) }} />
                                    </InputGroup>

                                    <InputGroup className="mb-5 p-3" >
                                        <InputGroup.Text>Présentation chariot </InputGroup.Text>
                                        <Form.Control type="Texte" value={cart} onChange={(event) => { setCart(event.target.value) }} />
                                    </InputGroup>

                                    <InputGroup className="mb-5 p-3" >
                                        <InputGroup.Text>Utilisation respective des produits et dosages </InputGroup.Text>
                                        <Form.Control type="Texte" value={products} onChange={(event) => { setProducts(event.target.value) }} />
                                    </InputGroup>

                                    <InputGroup className="mb-5 p-3" >
                                        <InputGroup.Text>Respect des codes couleurs </InputGroup.Text>
                                        <Form.Control type="Texte" value={colors} onChange={(event) => { setColors(event.target.value) }} />
                                    </InputGroup>

                                    <InputGroup className='mb-5 p-3'>
                                        <InputGroup.Text>Constatations sur le nettoyage</InputGroup.Text>
                                        <FormControl as="textarea" value={remarks} onChange={(event) => { setRemarks(event.target.value) }} aria-label="With textarea" />
                                    </InputGroup>

                                    <InputGroup className='mb-5 p-3'>
                                        <InputGroup.Text>Actions à prendre</InputGroup.Text>
                                        <FormControl as="textarea" value={actions} onChange={(event) => { setActions(event.target.value) }} aria-label="With textarea" />
                                    </InputGroup>


                                    <InputGroup className="mb-5 p-3 mt-5">
                                        <InputGroup.Text>Nom du Client</InputGroup.Text>
                                        <Form.Control type="Texte" value={clientName} onChange={(event) => { setClientName(event.target.value) }} />
                                    </InputGroup>

                                    <p className='text-center h5'>Signature du Client</p>
                                    <p className='text-secondary text-center mb-5 printP'>La signature n'est pas modifiable</p>
                                    <div className="signature mb-5">
                                        <img src={signature} alt="" />
                                    </div>

                                    <h5>Marquer ce rapport comme "Vérifié" : </h5>
                                    <ToggleButton
                                        id="toggle-check"
                                        type="checkbox"
                                        variant="warning"
                                        value={checked}
                                        onClick={(e) => setChecked("Vérifié")}
                                        className={checked === "Vérifié" ? "mb-3 text-white bg-success h6" : "mb-3 bg-warning text-dark h6"}
                                        
                                    >
                                        {checked === undefined || checked === '' ? "Non Vérifié" : checked}
                                        
                                    </ToggleButton>
                                    <p>Cliquer sur Modifier pour valider</p>
                                    <div className='mt-5 mb-5'>
                                        <button className="button" type="submit"> Modifier</button>
                                    </div>
                                    {images.map((image) => {
                                        return (

                                            <div key={image} className='mt-5 mb-3'>

                                                <img src={image} alt="" style={{ width: '500px', margin: '10px' }} />
                                            </div>
                                        )


                                    })}

                                    

                                </form>
                            </Container>
                            <div className="p-2 h5 m-2 text-center">

                                {message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>{message?.msg}</Alert>)}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default RapportPassageSingle