import './homeSidebar.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SourceIcon from '@mui/icons-material/Source';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { NavLink } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'
import { Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import NotificationDataService from '../../services/notificationsService'



function HomeSidebar() {

    //Log out
    const { logOut } = useUserAuth()

    const handleLogOut = async () => {
        try {
            localStorage.removeItem('isAdmin')
            await logOut()
        }
        catch (err) {
            console.log(err.message)
        }
    }

    //supprimer les notifications 
    const [rapportsP, setRapportsP] = useState([])
    const [rapportsQ, setRapportsQ] = useState([])
    const [rapportsM, setRapportsM] = useState([])




    useEffect(() => {
        getRapports()
    }, [])

    const getRapports = async () => {
        const dataP = await NotificationDataService.getPassageNotifications()
        setRapportsP(dataP.docs.map((doc) => ({ ...doc.data(), id: doc.id })))


        const dataQ = await NotificationDataService.getQualityNotifications()
        setRapportsQ(dataQ.docs.map((doc) => ({ ...doc.data(), id: doc.id })))


        const dataM = await NotificationDataService.getMaterieleNotifications()
        setRapportsM(dataM.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }
    const deleteRapport = async (id) => {
        await NotificationDataService.deleteNotification(id)

    }

    const handleClickP = async () => {

        rapportsP.map((rapportP) => {
            deleteRapport(rapportP.id)
        })

    }

    const handleClickM = async () => {

        rapportsM.map((rapportM) => {
            deleteRapport(rapportM.id)
        })

    }

    const handleClickQ = async () => {

        rapportsQ.map((rapportQ) => {
            deleteRapport(rapportQ.id)
        })

    }

    return (
        
            
                <div className="homeSidebar">
                    <div className='top'>
                        <NavLink to="/home">
                            <span className="logo">NetCleaning</span>
                        </NavLink>
                    </div>
                    <hr />
                    <div className='center'>
                        
                        <ul>
                            <p className="title">MAIN</p>
                            <NavLink to="/home" className={({ isActive }) => (isActive ? "link-active" : "link")} >
                                <li>
                                    <DashboardIcon className="icon" />
                                    Dashboard
                                </li>
                            </NavLink>
                            <p className="title">LISTES</p>
                            <NavLink to="/clients" className={({ isActive }) => (isActive ? "link-active" : "link")}>
                                <li>
                                    <FactoryOutlinedIcon className="icon" />
                                    Clients
                                </li>
                            </NavLink>
                            <NavLink to="/inactive" className={({ isActive }) => (isActive ? "link-active" : "link")}>
                                <li>
                                    <SourceIcon className="icon" />
                                    Clients inactifs
                                </li>
                            </NavLink>
                            
                            <NavLink to="/products" className={({ isActive }) => (isActive ? "link-active" : "link")}>
                                <li>
                                    <QrCode2Icon className="icon" />
                                    Produits
                                </li>
                            </NavLink>

                            <p className="title">RAPPORTS</p>
                            <NavLink onClick={handleClickP} to="/listRapportsPassage" className={({ isActive }) => (isActive ? "link-active" : "link")}>
                                <li>
                                    <AutoStoriesOutlinedIcon className="icon" />
                                    Rapports Passage
                                </li>
                            </NavLink>
                            <NavLink onClick={handleClickM} to="/listCheckMateriels" className={({ isActive }) => (isActive ? "link-active" : "link")}>
                                <li>
                                    <FactCheckOutlinedIcon className="icon" />
                                    Check Matériel
                                </li>
                            </NavLink>
                            <NavLink onClick={handleClickQ} to="/listSuiviQualite" className={({ isActive }) => (isActive ? "link-active" : "link")}>
                                <li>
                                    <AssistantPhotoOutlinedIcon className="icon" />
                                    Suivi Qualité
                                </li>
                            </NavLink>
                            <p className="title">UTILISATEUR</p>
                            <NavLink to="/users" className={({ isActive }) => (isActive ? "link-active" : "link")}>
                                <li>
                                    <AccountCircleOutlinedIcon className="icon" />
                                    Utilisateurs
                                </li>
                            </NavLink>

                            <li className='mt-3'>
                                <Button variant="warning" onClick={handleLogOut}>
                                    Déconnexion
                                </Button>
                            </li>

                        </ul>
                    </div>

                </div>
           
    )
}

export default HomeSidebar