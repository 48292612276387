import { Alert } from "react-bootstrap"



const ConfirmDelete = ({ deleteImages, close, message, setMessage }) => {



    return (
        <>
            <div className="container-fluid d-flex align-items-center justify-content-center text-center">
                <form>
                    <div className="mt-4 bg-danger p-5 rounded">
                        <h3 className="mb-5 mt-5 text-white">Etes vous certain de vouloir supprimer ce rapport ?</h3>
                    </div>
                    <button type="button" className="btn btn-secondary me-5 mt-5" onClick={close}>Annuler</button>
                    <button type="button" className="btn btn-danger mt-5" onClick={deleteImages}>Supprimer</button>
                </form>

            </div>

            <div className="p-2 h4 m-5 text-center">
                {message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>{message?.msg}</Alert>)}
            </div>
            <button onClick={close} className="mt-3 ms-4 text-white bg-primary">Retour à la liste</button>
        </>

    )
}

export default ConfirmDelete