import HomeNavbar from '../../components/homeNavbar/HomeNavbar'
import HomeSidebar from '../../components/homeSidebar/HomeSidebar'
import { Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import './listSuiviQualite.scss'
import SuiviQualiteDataService from '../../services/suiviQualiteServices'
import DataTableSuiviQualite from '../../components/dataTableSuiviQualite/DataTableSuiviQualite'
import SuiviQualiteSingle from '../rapports/suiviQualiteSingle/SuiviQualiteSingle'

function ListSuiviQualite(){

    const [rapports,setRapports] = useState([])

    useEffect(() => {
        getRapports()
    },[])

    const getRapports = async () => {
        const data = await SuiviQualiteDataService.getSuivisQualites()
        setRapports(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    const [rapportId, setRapportId] = useState('')

    const [open, setOpen] = useState(false)

    const handleGetRapportId = (id) => {
        setRapportId(id)
        setOpen(true)
    }

    const close = () => {
        setOpen(false)
    }

    const show = () => {
        setOpen(true)
    }

    return(
        <div className="suiviQualite">
            <HomeSidebar/>
            <div className="suiviQualiteContainer">
                <HomeNavbar/>
                <h1 className='text-center mt-5'>Contrôles Qualité Locaux</h1>
                
                {(open === false ) ? <DataTableSuiviQualite getRapportId={handleGetRapportId} open={show}/> : <SuiviQualiteSingle id={rapportId} setRapportId={setRapportId} />}
                {(open === true ) ? <div className='mb-2'>
                    <Button className='retour m-3 p-3' onClick={close}>Retour à la liste</Button></div> : ""}
            </div>
        </div>
    )
}

export default ListSuiviQualite