import { db } from '../firebase-config'
import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc, query, where, orderBy } from 'firebase/firestore'

const clientsCollectionRef = collection(db,"clients")


class ClientDataService {
    addClients = ( newClient ) => {
        return addDoc(clientsCollectionRef,newClient)
    }

    updateClient = (id,updatedClient) => {
        const clientDoc = doc(db,"clients",id)
        return updateDoc(clientDoc, updatedClient)
    }

    deleteClient = (id) => {
        const clientDoc = doc(db,"clients",id)
        return deleteDoc(clientDoc)
    }

    getActiveClients = () => {
        const activeClients = query(clientsCollectionRef,where('status','==','Actif'))
        return getDocs(activeClients)
    }

    getInactiveClients = () => {
        const inactiveClients = query(clientsCollectionRef,where('status','==','Inactif'))
        return getDocs(inactiveClients)
    }

   
    getClient = (id) => {
        const clientDoc = doc(db,"clients",id)
        return getDoc(clientDoc)
    }
}

export default new ClientDataService()