import { Navigate } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'



const ClientRoute = ({ children }) => {

    const admin = localStorage.getItem("isAdmin")
    const client = localStorage.getItem("isClient")

    let {user} = useUserAuth()
    
    if (!user) {
        return <Navigate to='/' />
    }

    else if( admin || client ){
        return children
    }

}

export default ClientRoute