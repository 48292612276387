import { db } from '../firebase-config'
import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc, query, where, orderBy } from 'firebase/firestore'

const privateUsersCollectionRef = collection(db,"privateUsers")


class PrivateUserDataService {
    addPrivateUser = ( newPrivateUser ) => {
        return addDoc(privateUsersCollectionRef,newPrivateUser)
    }

    updatePrivateUser = (id,updatedPrivateUser) => {
        const privateUserDoc = doc(db,"privateUsers",id)
        return updateDoc(privateUserDoc, updatedPrivateUser)
    }

    deletePrivateUser = (id) => {
        const privateUserDoc = doc(db,"privateUsers",id)
        return deleteDoc(privateUserDoc)
    }

    getPrivateUsers = () => {
        const sortedPrivateUsers = query(privateUsersCollectionRef,orderBy('role'))
        return getDocs(sortedPrivateUsers)
    }

    getAdminUsers = () => {
        const adminUsers = query(privateUsersCollectionRef,where('role','==','Admin'))
        return getDocs(adminUsers)
    }

    getWorkerUsers = () => {
        const workerUsers = query(privateUsersCollectionRef,where('role','==','Employé'))
        return getDocs(workerUsers)
    }
    
    getClientUsers = () => {
        const clientUsers = query(privateUsersCollectionRef,where('role','==','Client'))
        return getDocs(clientUsers)
    } 

    getPrivateUser = (id) => {
        const privateUserDoc = doc(db,"privateUsers",id)
        return getDoc(privateUserDoc)
    }

}

export default new PrivateUserDataService()