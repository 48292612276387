import './dataTableClients.scss'
import { DataGrid } from '@mui/x-data-grid';
import { clientColumns } from '../../dataTableClients'
import { useState, useEffect } from 'react'
import ClientDataService from '../../services/clientsService'

function DatatableClients({ getClientId, open }) {

    //voir les clients
    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    /*const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }*/

    const getClients = async () => {
        try {
            const data = await ClientDataService.getActiveClients();
            const clientsList = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));


            const sortedClients = clientsList.sort((a, b) => a.client.localeCompare(b.client));

            setClients(sortedClients);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    };

    

    //boutons modifier et supprimer dans la table
    const actionColumn = [{
        field: "action",
        headerName: "",
        width: 80,
        renderCell: (params) => {
            return (
                <div className='cellAction'>
                    <div className="editButton" onClick={(e) => getClientId(params.row.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="VOIR DETAIL OU MODIFIER"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffa500" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg></div>
                </div>
            )
        }
    }]

    return (
        <div className="datatableClients">
            <div className="datatableTitle">

                <button onClick={open} className="link">
                    Ajouter un client
                </button>
            </div>
            <DataGrid
                rows={clients}
                columns={clientColumns.concat(actionColumn)}
                pageSize={10}
                rowsPerPageOptions={[10]}
            // checkboxSelection
            />
            <p className='text-danger'>! Pour valider l'accès à l'Espace clients, le client doit aussi être rajouté à la liste des Utilisateurs !</p>
        </div>
    )
}

export default DatatableClients